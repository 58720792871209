import { Button } from '@randstad-lean-mobile-factory/react-components-core';
import React from 'react';

import { randstad } from 'src/Assets';

import styles from './Login.module.scss';
import { Props } from './Login.types';

const Login = ({ appName, description, icon, style, titleStyle, onLogin, disabled }: Props) => (
  <div className={styles.container}>
    <div className={styles.modal} style={style}>
      <img src={icon} alt={`${appName} icon`} className={styles.logo} />
      <p className={styles.title} style={titleStyle}>
        Bienvenue <br /> sur {appName}
      </p>
      <p className={styles.description}>{description}</p>
      <img src={randstad} alt={`randstad icon`} className={styles.randstad} />
      <Button.Primary className={styles.button} disabled={disabled} onClick={onLogin} darkMode>
        se connecter
      </Button.Primary>
    </div>
  </div>
);

export default Login;
