import React from 'react';
import { useSelector } from 'react-redux';

import { logo } from 'src/Assets';
import { getCurrentBrand } from 'src/Redux/Perimeter/Selectors';

import Apps from './Apps';
import Parameters from './Parameters';
import styles from './TopBar.module.scss';

const TopBar = () => {
  const currentBrand = useSelector(getCurrentBrand);
  const displayedBrand = currentBrand?.brand?.name?.toLocaleLowerCase() ?? '';

  return (
    <div className={styles.container}>
      <div className={styles.subContainer}>
        <img src={logo} className={styles.logo} alt="logo" />
        <div className={styles.greySeparator} />
        <h1 className={styles.title}>satisfaction</h1>
        <div className={styles.navySeparator} />
        <h1 className={styles.subtitle}>{displayedBrand}</h1>
      </div>
      <div className={styles.subContainer}>
        <Apps />
        <Parameters />
      </div>
    </div>
  );
};
export default TopBar;
