/** Generate by swagger-axios-codegen */
// tslint:disable
/* eslint-disable */
import axiosStatic, { AxiosInstance } from 'axios';

export interface IRequestOptions {
  headers?: any;
  baseURL?: string;
  responseType?: string;
}

export interface IRequestConfig {
  method?: any;
  headers?: any;
  url?: any;
  data?: any;
  params?: any;
}

// Add options interface
export interface ServiceOptions {
  axios?: AxiosInstance;
}

// Add default options
export const serviceOptions: ServiceOptions = {};

// Instance selector
export function axios(
  configs: IRequestConfig,
  resolve: (p: any) => void,
  reject: (p: any) => void
): Promise<any> {
  if (serviceOptions.axios) {
    return serviceOptions.axios
      .request(configs)
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err);
      });
  } else {
    throw new Error('please inject yourself instance like axios  ');
  }
}

export function getConfigs(
  method: string,
  contentType: string,
  url: string,
  options: any
): IRequestConfig {
  const configs: IRequestConfig = { ...options, method, url };
  configs.headers = {
    ...options.headers,
    'Content-Type': contentType,
  };
  return configs;
}

const basePath = '';

export interface IList<T> extends Array<T> {}
export interface List<T> extends Array<T> {}
export interface IDictionary<TValue> {
  [key: string]: TValue;
}
export interface Dictionary<TValue> extends IDictionary<TValue> {}

export interface IListResult<T> {
  items?: T[];
}

export class ListResultDto<T> implements IListResult<T> {
  items?: T[];
}

export interface IPagedResult<T> extends IListResult<T> {
  totalCount?: number;
  items?: T[];
}

export class PagedResultDto<T> implements IPagedResult<T> {
  totalCount?: number;
  items?: T[];
}

// customer definition
// empty

export class AssessmentsService {
  /**
   *
   */
  static assessmentsControllerGetAssessments(
    params: {
      /** requestBody */
      body?: GetAssessmentsBody;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Assessment[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/assessments/search';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static assessmentsControllerCreateAssessment(
    params: {
      /** requestBody */
      body?: CreateAssessmentBody;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/assessments';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static assessmentsControllerUpdateAssessment(
    params: {
      /**  */
      id: string;
      /** requestBody */
      body?: UpdateAssessmentBody;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AssessmentDetails> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/assessments/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static assessmentsControllerGetAssessmentDetail(
    params: {
      /**  */
      id: string;
      /**  */
      brandCode: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AssessmentDetails> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/assessments/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { brandCode: params['brandCode'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static assessmentsControllerGetAssessmentPdf(
    params: {
      /**  */
      id: string;
      /** requestBody */
      body?: GetAssessmentPdfBody;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/assessments/{id}/pdf';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static assessmentsControllerSendAssessmentEmail(
    params: {
      /**  */
      id: string;
      /** requestBody */
      body?: GetAssessmentMailBody;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/assessments/{id}/mail';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class CompaniesService {
  /**
   *
   */
  static companiesControllerGetCompanies(
    params: {
      /** requestBody */
      body?: GetCompaniesBody;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CompanySearchResult[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/companies';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static companiesControllerGetCompanyDetail(
    params: {
      /**  */
      id: string;
      /**  */
      brandCode: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CompanyDetails> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/companies/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { brandCode: params['brandCode'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static companiesControllerGetCompanyContacts(
    params: {
      /**  */
      id: string;
      /**  */
      brandCode: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CompanyContact[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/companies/{id}/contacts';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { brandCode: params['brandCode'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class RepositoriesService {
  /**
   *
   */
  static repositoriesControllerGetAssessmentTypes(
    params: {
      /**  */
      brandCode: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AssessmentType[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/repositories/assessmentTypes';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { brandCode: params['brandCode'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static repositoriesControllerGetQuestions(
    params: {
      /**  */
      questionFamiliesId: string;
      /**  */
      brandCode: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Question[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/repositories/questions';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        questionFamiliesId: params['questionFamiliesId'],
        brandCode: params['brandCode'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static repositoriesControllerGetQuestionFamilies(
    params: {
      /**  */
      assessmentTypeId: string;
      /**  */
      brandCode: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<QuestionFamily[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/repositories/questionFamilies';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        assessmentTypeId: params['assessmentTypeId'],
        brandCode: params['brandCode'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ConsultantsService {
  /**
   *
   */
  static consultantsControllerGetPerimeter(
    options: IRequestOptions = {}
  ): Promise<ConsultantPerimeter> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/consultants/perimeter';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export interface GetAssessmentsBody {
  /**  */
  agencies: string[];

  /**  */
  offset: number;

  /**  */
  limit: number;

  /**  */
  brandCode: string;

  /**  */
  companyId?: string;
}

export interface OSMCommonModelDateDto {
  /**  */
  year?: number;

  /**  */
  month?: number;

  /**  */
  day?: number;
}

export interface OSMAssessmentModelDtoAssessmentMultiCriteriaCompanyDto {
  /**  */
  id?: string;

  /**  */
  label?: string;
}

export interface OSMAssessmentModelDtoAssessmentMultiCriteriaServiceDto {
  /**  */
  id?: string;

  /**  */
  label?: string;
}

export interface OSMAssessmentModelDtoAssessmentMultiCriteriaContactDto {
  /**  */
  id?: string;

  /**  */
  name?: string;

  /**  */
  firstName?: string;
}

export interface OSMAssessmentModelDtoAssessmentMultiCriteriaTypeDto {
  /**  */
  id?: string;

  /**  */
  label?: string;
}

export interface Assessment {
  /**  */
  id?: string;

  /**  */
  assessmentDate?: OSMCommonModelDateDto;

  /**  */
  company?: OSMAssessmentModelDtoAssessmentMultiCriteriaCompanyDto;

  /**  */
  services?: OSMAssessmentModelDtoAssessmentMultiCriteriaServiceDto[];

  /**  */
  contacts?: OSMAssessmentModelDtoAssessmentMultiCriteriaContactDto[];

  /**  */
  type?: OSMAssessmentModelDtoAssessmentMultiCriteriaTypeDto;

  /**  */
  status?: AssessmentStatus;
}

export interface CreateAssessmentBody {
  /**  */
  assessmentTypeId: string;

  /**  */
  companyId: string;

  /**  */
  agencyId: string;

  /**  */
  contactId: string;

  /**  */
  brandCode: string;
}

export interface UpdateAssessmentResponse {
  /**  */
  value: string;

  /**  */
  questionId: string;
}

export interface UpdateAssessmentBody {
  /**  */
  status: AssessmentStatus;

  /**  */
  assessmentTypeId: string;

  /**  */
  companyId: string;

  /**  */
  agencyId: string;

  /**  */
  contactIds: string[];

  /**  */
  brandCode: string;

  /**  */
  responses: UpdateAssessmentResponse[];
}

export interface OSMAssessmentModelDtoAssessmentCompanyDto {
  /**  */
  id?: string;

  /**  */
  label?: string;
}

export interface OSMAssessmentModelDtoAssessmentServiceDto {
  /**  */
  id?: string;

  /**  */
  label?: string;
}

export interface OSMAssessmentModelDtoAssessmentContactDto {
  /**  */
  id?: string;

  /**  */
  name?: string;

  /**  */
  firstName?: string;
}

export interface OSMAssessmentModelDtoAssessmentResponseDto {
  /**  */
  id?: string;

  /**  */
  value?: string;

  /**  */
  questionId?: string;
}

export interface OSMAssessmentModelDtoAssessmentFamilyQuestionDto {
  /**  */
  id?: string;

  /**  */
  label?: string;

  /**  */
  responses?: OSMAssessmentModelDtoAssessmentResponseDto[];
}

export interface OSMAssessmentModelDtoAssessmentTypeDto {
  /**  */
  id?: string;

  /**  */
  label?: string;
}

export interface OSMAssessmentModelDtoBrandDto {
  /**  */
  code?: string;

  /**  */
  name?: string;
}

export interface AssessmentDetails {
  /**  */
  id?: string;

  /**  */
  assessmentDate?: OSMCommonModelDateDto;

  /**  */
  company?: OSMAssessmentModelDtoAssessmentCompanyDto;

  /**  */
  services?: OSMAssessmentModelDtoAssessmentServiceDto[];

  /**  */
  contacts?: OSMAssessmentModelDtoAssessmentContactDto[];

  /**  */
  questionFamilies?: OSMAssessmentModelDtoAssessmentFamilyQuestionDto[];

  /**  */
  type?: OSMAssessmentModelDtoAssessmentTypeDto;

  /**  */
  agencyId?: string;

  /**  */
  brand?: OSMAssessmentModelDtoBrandDto;

  /**  */
  status?: AssessmentStatus;
}

export interface GetAssessmentPdfBody {
  /**  */
  companyId: string;

  /**  */
  brandCode: string;
}

export interface GetAssessmentMailBody {
  /**  */
  brandCode: string;
}

export interface GetCompaniesBody {
  /**  */
  keyword: string;

  /**  */
  agencies: string[];

  /**  */
  brandCode: string;
}

export interface OSMCompanyModelCompanyDtoCompanyAddressDto {
  /**  */
  address1?: string;

  /**  */
  address2?: string;

  /**  */
  address3?: string;

  /**  */
  postalCode?: string;

  /**  */
  city?: string;
}

export interface CompanySearchResult {
  /**  */
  companyAddress?: OSMCompanyModelCompanyDtoCompanyAddressDto;

  /**  */
  companyId?: string;

  /**  */
  companyName?: string;

  /**  */
  siret?: string;

  /**  */
  status?: string;

  /**  */
  solvency?: string;
}

export interface OSMCompanyModelCompanyDtoNafDto {
  /**  */
  ID?: string;

  /**  */
  label?: string;
}

export interface OSMCompanyModelCompanyDtoAgencyStrategyDto {
  /**  */
  ID?: string;

  /**  */
  label?: string;
}

export interface OSMCompanyModelCompanyDtoCollectiveAgreementDto {
  /**  */
  ID?: string;

  /**  */
  label?: string;
}

export interface OSMCompanyModelCompanyDtoEstablishmentTypeDto {
  /**  */
  ID?: string;

  /**  */
  label?: string;
}

export interface OSMCompanyModelDtoCompanyAgencyPortfolioDto {
  /**  */
  code?: string;

  /**  */
  societyCode?: string;

  /**  */
  portfolioType?: string;
}

export interface OSMCompanyModelDtoCompanySectorDto {
  /**  */
  isPublic?: boolean;

  /**  */
  label?: string;
}

export interface OSMCompanyModelFrameworkAgreementDtoFrameworkAgreementDto {
  /**  */
  id?: string;

  /**  */
  name?: string;
}

export interface CompanyDetails {
  /**  */
  companyName?: string;

  /**  */
  siret?: string;

  /**  */
  NAF?: OSMCompanyModelCompanyDtoNafDto;

  /**  */
  acronym?: string;

  /**  */
  agencyStrategy?: OSMCompanyModelCompanyDtoAgencyStrategyDto;

  /**  */
  brandStrategy?: string;

  /**  */
  collectiveAgreement?: OSMCompanyModelCompanyDtoCollectiveAgreementDto;

  /**  */
  establishmentType?: OSMCompanyModelCompanyDtoEstablishmentTypeDto;

  /**  */
  portalFunction?: boolean;

  /**  */
  companyAddress?: OSMCompanyModelCompanyDtoCompanyAddressDto;

  /**  */
  companyOtherAddress?: OSMCompanyModelCompanyDtoCompanyAddressDto[];

  /**  */
  agencyPortfolios?: OSMCompanyModelDtoCompanyAgencyPortfolioDto[];

  /**  */
  solvency?: string;

  /**  */
  companySector?: OSMCompanyModelDtoCompanySectorDto;

  /**  */
  status?: string;

  /**  */
  servicesUri?: string;

  /**  */
  contactsUri?: string;

  /**  */
  practicalInformation?: string;

  /**  */
  frameworkAgreement?: OSMCompanyModelFrameworkAgreementDtoFrameworkAgreementDto;

  /**  */
  phone?: string;

  /**  */
  workforce?: number;
}

export interface OSMRepositoriesModelCivilityTitleRepositoryDtoCivilityTitleDto {
  /**  */
  id?: number;

  /**  */
  label?: string;
}

export interface OSMCompanyModelCompanyDtoCompanyContactServicesDto {
  /**  */
  id?: string;

  /**  */
  name?: string;
}

export interface CompanyContact {
  /**  */
  contactId?: string;

  /**  */
  firstName?: string;

  /**  */
  name?: string;

  /**  */
  position?: string;

  /**  */
  statut?: string;

  /**  */
  mobilePhoneNumber?: string;

  /**  */
  phone?: string;

  /**  */
  email?: string;

  /**  */
  isActiveOnCurrentCompany?: boolean;

  /**  */
  mainContact?: boolean;

  /**  */
  myContact?: boolean;

  /**  */
  dontMailMe?: boolean;

  /**  */
  dontCallMe?: boolean;

  /**  */
  dontPostMe?: boolean;

  /**  */
  dontPlaceMe?: boolean;

  /**  */
  civility?: OSMRepositoriesModelCivilityTitleRepositoryDtoCivilityTitleDto;

  /**  */
  services?: OSMCompanyModelCompanyDtoCompanyContactServicesDto[];

  /**  */
  portalAccess?: boolean;
}

export interface AssessmentType {
  /**  */
  id?: string;

  /**  */
  label?: string;

  /**  */
  shortLabel?: string;

  /**  */
  typology?: string;

  /**  */
  isActive?: boolean;
}

export interface OSMRepositoriesModelQuestionsRepositoryDtoQuestionFamiliesDto {
  /**  */
  id?: string;

  /**  */
  label?: string;
}

export interface Question {
  /**  */
  id?: string;

  /**  */
  label?: string;

  /**  */
  questionFamilies?: OSMRepositoriesModelQuestionsRepositoryDtoQuestionFamiliesDto;

  /**  */
  displayType?: string;

  /**  */
  displayOrder?: string;

  /**  */
  flagMandatoryResponse?: boolean;

  /**  */
  isActive?: boolean;
}

export interface OSMRepositoriesModelQuestionFamiliesRepositoryDtoAssessmentTypeDto {
  /**  */
  id?: string;

  /**  */
  label?: string;
}

export interface QuestionFamily {
  /**  */
  id?: string;

  /**  */
  label?: string;

  /**  */
  shortLabel?: string;

  /**  */
  assessmentType?: OSMRepositoriesModelQuestionFamiliesRepositoryDtoAssessmentTypeDto;

  /**  */
  displayOrder?: string;

  /**  */
  isActive?: boolean;
}

export interface OSMConsultantModelDtoBrandDto {
  /**  */
  code?: string;

  /**  */
  name?: string;
}

export interface OSMConsultantModelDtoSpecialityDto {
  /**  */
  id?: string;

  /**  */
  label?: string;
}

export interface OSMConsultantModelDtoConsultantAgencyByCompany {
  /**  */
  societyId?: string;

  /**  */
  brand?: OSMConsultantModelDtoBrandDto;

  /**  */
  brandCodeApiHeader?: string;

  /**  */
  agencies?: string[];

  /**  */
  specialities?: OSMConsultantModelDtoSpecialityDto[];
}

export interface ConsultantPerimeter {
  /**  */
  id?: string;

  /**  */
  name?: string;

  /**  */
  branchId?: string;

  /**  */
  regionId?: string;

  /**  */
  zoneId?: string;

  /**  */
  defaultAgencyId?: string;

  /**  */
  defaultCompanyId?: string;

  /**  */
  companies?: OSMConsultantModelDtoConsultantAgencyByCompany[];
}

export enum AssessmentStatus {
  'InProgress' = 'InProgress',
  'Finished' = 'Finished',
}
