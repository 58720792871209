import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import useAuth from '../Authentication/useAuth';
import { useModalWithLocation } from '../Navigation';

export const useCreateAssessmentFromURL = () => {
  const { isAuthenticated } = useAuth();
  const params = new URLSearchParams(window.location.search);
  const { open } = useModalWithLocation('create-assessment');
  const { location } = useHistory();
  useEffect(() => {
    if (
      isAuthenticated &&
      !location.pathname.includes('create-assessment') &&
      params.has('company')
    ) {
      const companyId = params.get('company') ?? undefined;
      const contactId = params.get('contact') ?? undefined;
      open({
        modalParameters: {
          companyId,
          contactId,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, location, window.location.search, isAuthenticated]);
};
