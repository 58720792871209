import { Locked } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import moment from 'moment';
import React from 'react';

import styles from './GridCard.module.scss';
import { Props } from './GridCard.types';

export const GridCard = ({
  contact,
  companyName,
  companyAddress,
  updatedAt,
  status,
  type,
  onClick,
  rightActions,
}: Props) => (
  <div className={styles.cardContainer} onClick={onClick}>
    <div className={styles.upperPart}>
      <div className={styles.firstRow}>
        <p className={styles.date}>
          {status === 'Finished' ? (
            <>
              <span>
                <Locked className={styles.icon} />
              </span>
              {`terminé le ${moment(updatedAt).format('LL')}`}
            </>
          ) : (
            <>{`modifié le ${moment(updatedAt).format('LL')}`}</>
          )}
        </p>
        {rightActions}
      </div>
      <p className={styles.companyName}>{companyName}</p>
      <p className={styles.companyAddress}>{companyAddress}</p>
    </div>
    <div className={styles.separator} />
    <div className={styles.lowerPart}>
      <p className={styles.type}>{type}</p>
      <p className={styles.contact}>{contact}</p>
    </div>
  </div>
);

export default GridCard;
