import React from 'react';

import { logo } from 'src/Assets';
import RawLogin from 'src/Components/Login';
import useAuth from 'src/Hooks/Authentication/useAuth';

const MemoLogin = React.memo(RawLogin);

const Login = () => {
  const { isLoading, loginWithRedirect } = useAuth();

  return (
    <MemoLogin
      disabled={isLoading ?? true}
      onLogin={() => loginWithRedirect({})}
      icon={logo}
      appName="satisfaction"
      description="votre outil de mesure qualité des prestations Randstad"
      titleStyle={{ color: '#ffffff' }}
      style={{ color: '#ffffff' }}
    />
  );
};

export default Login;
