import { ExclusiveSelectionGroup } from '@randstad-lean-mobile-factory/react-components-core';
import React from 'react';

import styles from './R5Component.module.scss';
import { Props } from './R5Component.types';

const R5Component = ({
  label,
  mandatory,
  value,
  onChange,
  disabled = false,
  canBeReset = false,
}: Props) => {
  const items = Array(5)
    .fill(undefined)
    .map((_, index) => (index + 1).toString());
  return (
    <div className={styles.container}>
      <div className={styles.label}>{mandatory ? `* ${label} (*obligatoire)` : label}</div>
      <div className={styles.question}>
        <ExclusiveSelectionGroup
          selected={value}
          values={items}
          getKey={key => key}
          getStringValue={value => (parseInt(value, 10) < 5 ? value : 'NS')}
          setSelected={value => onChange(value ?? '')}
          disabled={disabled}
          canBeReset={canBeReset}
        />
      </div>
    </div>
  );
};

export default R5Component;
