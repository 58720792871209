import {
  Button,
  FetchButton,
  Modal,
  PopupActions,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import moment from 'moment';
import React, { useContext, useState } from 'react';

import { IllusWarning } from 'src/Assets';
import { useGenerateAssessmentPdf } from 'src/Hooks/Assessment/useGenerateAssessmentPdf';
import { FormStatusContext } from 'src/Hooks/Navigation/useFormNavigationContextElements';
import { FETCH_STATUS } from 'src/Redux/Types';
import { mergeFetchStatusWithFormState } from 'src/Services/Async';
import { onFileOpen } from 'src/Utils/onFileOpen';

import styles from './PdfWarningModal.module.scss';
import { Props } from './PdfWarningModal.types';

const PdfWarningModal = ({ open, onClose, assessmentId, companyId }: Props) => {
  const innerRef = React.useRef<PopupActions>(null);
  const currentDate = moment();
  const {
    fetchStatus,
    setPdfFetchStatus,
    handleSubmit,
    setFormState,
    formState: { isSubmitting, isSubmitted, isSubmitSuccessful },
  } = useContext(FormStatusContext);
  const [isTouched, setIsTouched] = useState(true);
  const generateAssessmentPdf = useGenerateAssessmentPdf();
  return (
    <Modal
      open={open}
      title="attention"
      ref={innerRef}
      onClose={onClose}
      icon={<IllusWarning />}
      footerActionsRight={[
        <Button.Secondary
          text="générer sans sauvegarder"
          onClick={() => {
            setPdfFetchStatus(FETCH_STATUS.PENDING);
            generateAssessmentPdf.mutate(
              { assessmentId: assessmentId ?? '', companyId: companyId ?? '' },
              {
                onSuccess: buffer => {
                  setPdfFetchStatus(FETCH_STATUS.FULFILLED);
                  onFileOpen(buffer, 'application/pdf');
                },
              }
            );
            onClose();
          }}
        />,
        <FetchButton
          title="sauvegarder et générer"
          className={styles.fetchButton}
          fetchStatus={
            isTouched
              ? FETCH_STATUS.FULFILLED
              : mergeFetchStatusWithFormState(fetchStatus, {
                  isSubmitting,
                  isSubmitSuccessful,
                  isSubmitted,
                })
          }
          onClick={() => {
            setIsTouched(false);
            setPdfFetchStatus(FETCH_STATUS.PENDING);
            handleSubmit();
          }}
          onBeforeSuccessAnimation={() => {
            generateAssessmentPdf.mutate(
              { assessmentId: assessmentId ?? '', companyId: companyId ?? '' },
              {
                onSuccess: buffer => {
                  setPdfFetchStatus(FETCH_STATUS.FULFILLED);
                  onFileOpen(buffer, 'application/pdf');
                },
              }
            );
            setFormState({ isSubmitSuccessful: false, isSubmitted: false, isSubmitting: false });
            setIsTouched(true);
          }}
          onSuccess={() => {
            onClose();
          }}
          onError={() => {
            setFormState({ isSubmitSuccessful: false, isSubmitted: false, isSubmitting: false });
            setIsTouched(true);
            onClose();
          }}
        />,
      ]}
    >
      <WithLightTitle
        title={`le ${currentDate.format('L')} à ${currentDate.hours()}:${currentDate.minutes()}`}
      >
        <div className={styles.text}>
          Vous souhaitez générer le pdf mais vos données ne sont pas sauvegardées
        </div>
      </WithLightTitle>
    </Modal>
  );
};

export default PdfWarningModal;
