import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../RootReducer';

import { PerimeterState } from './Types';

export const getPerimeterState = (state: RootState) => state.perimeter;

export const getBrands = createSelector(
  [getPerimeterState],
  (state: PerimeterState) => state.perimeter?.companies
);

export const getCurrentBrand = createSelector(
  [getPerimeterState],
  (state: PerimeterState) => state.currentBrand
);

export const getCurrentAgency = createSelector(
  [getPerimeterState],
  (state: PerimeterState) => state.currentAgency
);

export default getPerimeterState;
