import { AppState, Auth0Provider } from '@auth0/auth0-react';
import React from 'react';

import history from 'src/Utils/history';

import { ReactQueryProvider } from './ReactQuery';
import { ReduxProvider } from './Redux';

const onRedirectCallback = (appState: AppState) => {
  // Use the router's history module to replace the url
  history.replace(appState?.returnTo || window.location.pathname);
};

const Providers = ({ children }: { children: React.ReactElement }) => (
  <Auth0Provider
    domain={process.env.REACT_APP_AUTH0_DOMAIN ?? ''}
    audience={process.env.REACT_APP_AUTH0_AUDIENCE ?? ''}
    clientId={process.env.REACT_APP_AUTH0_CLIENT_ID ?? ''}
    redirectUri={process.env.REACT_APP_AUTH0_REDIRECT_URI ?? ''}
    onRedirectCallback={onRedirectCallback}
  >
    <ReduxProvider>
      <ReactQueryProvider>{children}</ReactQueryProvider>
    </ReduxProvider>
  </Auth0Provider>
);

export default Providers;
