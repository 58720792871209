import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { useSelector } from 'react-redux';

import { getCurrentBrand } from 'src/Redux/Perimeter/Selectors';
import { AssessmentsService } from 'src/Services/API';

import { MutationKeys } from '../types';

export const useGenerateAssessmentPdf = (
  extraOptions?: UseMutationOptions<Buffer, unknown, unknown>
) => {
  const currentBrand = useSelector(getCurrentBrand);
  return useMutation(
    [MutationKeys.generateAssessmentPdf],
    async ({ assessmentId, companyId }: { assessmentId: string; companyId: string }) => {
      if (!currentBrand || !currentBrand.brandCodeApiHeader || !currentBrand.agencies) {
        return Promise.reject('No current brand or has an invalid current brand');
      }
      const assessment = await AssessmentsService.assessmentsControllerGetAssessmentPdf(
        { id: assessmentId, body: { brandCode: currentBrand?.brandCodeApiHeader, companyId } },
        { responseType: 'arraybuffer' }
      );
      return assessment;
    },
    {
      ...extraOptions,
    }
  );
};
