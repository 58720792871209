import { UseInfiniteQueryOptions, useInfiniteQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';

import { getCurrentBrand, getCurrentAgency } from 'src/Redux/Perimeter/Selectors';
import { AssessmentsService, CompaniesService, GetAssessmentsBody } from 'src/Services/API';

import { AssessmentWithCompanyAddress, QueryKeys } from '../types';

const DEFAULT_ASSESSMENTS_PER_PAGE = 10;

export const useFetchAssessments = (
  filters: Partial<
    Omit<
      // We use required because as any is autogenerated in arg typing, resulting in weak typing
      Required<GetAssessmentsBody>,
      'agencyId' | 'brandCode'
    >
  >,
  extraOptions?: UseInfiniteQueryOptions<
    AssessmentWithCompanyAddress[],
    unknown,
    AssessmentWithCompanyAddress[],
    AssessmentWithCompanyAddress[],
    (string | undefined | Partial<Omit<GetAssessmentsBody, 'agencyId' | 'brandCode'>>)[]
  >
) => {
  const currentBrand = useSelector(getCurrentBrand);
  const currentAgency = useSelector(getCurrentAgency);
  return useInfiniteQuery(
    [QueryKeys.fetchAssessments, filters, currentBrand?.brandCodeApiHeader, currentAgency],
    async ({ pageParam = 0 }) => {
      if (!currentBrand || !currentBrand.brandCodeApiHeader || !currentAgency) {
        return Promise.reject('No current brand or has an invalid current brand');
      }
      const assessments = await AssessmentsService.assessmentsControllerGetAssessments({
        body: {
          offset: pageParam * DEFAULT_ASSESSMENTS_PER_PAGE,
          limit: DEFAULT_ASSESSMENTS_PER_PAGE,
          brandCode: currentBrand.brandCodeApiHeader,
          agencies: [currentAgency],
          ...filters,
        },
      });
      const assessmentsWithCompanyAddress = [];
      for (const assessment of assessments) {
        const companyDetails =
          assessment.company?.id !== undefined
            ? await CompaniesService.companiesControllerGetCompanyDetail({
                id: assessment.company.id,
                brandCode: currentBrand.brandCodeApiHeader,
              })
            : undefined;
        assessmentsWithCompanyAddress.push({
          ...assessment,
          companyAddress: companyDetails?.companyAddress,
        });
      }
      return assessmentsWithCompanyAddress;
    },
    {
      getNextPageParam: (lastPage, allPages) =>
        lastPage.length < DEFAULT_ASSESSMENTS_PER_PAGE ? undefined : allPages.length,
      enabled: currentBrand !== undefined,
      staleTime: 300000,
      refetchOnWindowFocus: false,
      ...extraOptions,
    }
  );
};
