import React from 'react';
import { Route, Router, Switch, useLocation, useRouteMatch } from 'react-router-dom';

import PrivateRoute from 'src/Components/PrivateRoute';
import { useCreateAssessmentFromURL } from 'src/Hooks/Assessment/useCreateAssessmentFromURL';
import { useCheckActivePerimeter } from 'src/Hooks/Perimeter';
import {
  AssessmentRoute,
  CompanyResultRoute,
  CompanyResultState,
  LocationState,
} from 'src/Services/Routing';
import history from 'src/Utils/history';

import AssessmentDetails from '../AssessmentDetails';
import AssessmentModification from '../AssessmentDetails/AssessmentModification';
import CompanyAssessments from '../CompanyAssessments';
import Home from '../Home';

import styles from './MainPage.module.scss';
import TopBar from './TopBar';

const companyRoute: CompanyResultRoute = {
  route: '/company/:companyId?',
  component: CompanyAssessments,
};

export const assessmentRoute: AssessmentRoute = {
  route: companyRoute.route + '/assessment/:assessmentId/:firstSection/',
  component: AssessmentDetails,
};

export const assessmentModificationRoute: AssessmentRoute = {
  route: assessmentRoute.route.replace(':firstSection', 'content/:secondSection?'),
  component: AssessmentModification,
};

export const KPIRoute: AssessmentRoute = {
  route: assessmentRoute.route.replace(':firstSection', 'KPI/:secondSection?'),
  component: () => <div>kpi</div>,
};

const MainPage = () => {
  useCheckActivePerimeter();
  useCreateAssessmentFromURL();
  const match = useRouteMatch();
  const location = useLocation<LocationState>();
  const locationState = location.state;
  const background = locationState?.background;
  const companyResultLocation = useLocation<CompanyResultState>();
  const companyResultLocationState = companyResultLocation.state;

  return (
    <div className={styles.container}>
      <TopBar />
      <Router history={history}>
        <Switch location={background || location}>
          <PrivateRoute exact path={match.path} component={Home} />
          {/* TODO remove children */}
          <PrivateRoute
            exact
            path={companyRoute.route}
            children={
              <companyRoute.component
                routeParameters={companyResultLocationState?.routeParameters}
              />
            }
          />
          <Route path={assessmentRoute.route} component={assessmentRoute.component} />
        </Switch>
      </Router>
    </div>
  );
};

export default MainPage;
