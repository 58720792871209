import { Locked } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import moment from 'moment';
import React from 'react';

import styles from './ListCard.module.scss';
import { Props } from './ListCard.types';

export const ListCard = ({
  contact,
  companyName,
  companyAddress,
  updatedAt,
  status,
  type,
  onClick,
  rightActions,
}: Props) => (
  <div className={styles.container} onClick={onClick}>
    <div className={styles.firstPart}>
      <p className={styles.date}>
        {status === 'Finished' ? (
          <>
            <span>
              <Locked className={styles.icon} />
            </span>
            {`terminé le ${moment(updatedAt).format('LL')}`}
          </>
        ) : (
          <>{`modifié le ${moment(updatedAt).format('LL')}`}</>
        )}
      </p>
      <p className={styles.companyName}>{companyName}</p>
      <p className={styles.companyAddress}>{companyAddress}</p>
    </div>
    <div className={styles.separator}></div>
    <div className={styles.secondPart}>
      <p className={styles.light}>{type}</p>
      <p className={styles.contact}>{contact}</p>
    </div>
    <div className={styles.thirdPart}>{rightActions}</div>
  </div>
);

export default ListCard;
