import { PopupMenu } from '@randstad-lean-mobile-factory/react-components-core';
import { DocumentPDF, Refresh } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import React, { useContext } from 'react';

import { useGenerateAssessmentPdf } from 'src/Hooks/Assessment/useGenerateAssessmentPdf';
import { FormStatusContext } from 'src/Hooks/Navigation/useFormNavigationContextElements';
import { FETCH_STATUS } from 'src/Redux/Types';
import { mergeFetchStatus, toFetchStatus } from 'src/Services/Async';
import { onFileOpen } from 'src/Utils/onFileOpen';

import styles from './PDFGenerationButton.module.scss';
import { Props } from './PDFGenerationButton.types';

const PDFGenerationButton = ({ assessmentId, companyId }: Props) => {
  const generateAssessmentPdf = useGenerateAssessmentPdf();
  const formStatusContext = useContext(FormStatusContext);

  return (
    <PopupMenu.Item
      icon={
        mergeFetchStatus(formStatusContext.pdfFetchStatus, toFetchStatus(generateAssessmentPdf)) ===
        FETCH_STATUS.PENDING ? (
          <Refresh className={styles.loading} />
        ) : (
          <DocumentPDF />
        )
      }
      text="PDF bilan"
      keepOpenOnClick
      onClick={() => {
        if (formStatusContext.hasFormBeenTouched) {
          formStatusContext.openPdfWarningModal();
          formStatusContext.setFetchStatus(FETCH_STATUS.FULFILLED);
        } else {
          generateAssessmentPdf.mutate(
            { assessmentId: assessmentId ?? '', companyId: companyId ?? '' },
            {
              onSuccess: buffer => onFileOpen(buffer, 'application/pdf'),
            }
          );
        }
      }}
      disabled={assessmentId === undefined || companyId === undefined}
    />
  );
};

export default PDFGenerationButton;
