import classnames from 'classnames';
import React from 'react';
import { Route, Router, Switch, useHistory, useParams } from 'react-router-dom';

import styles from './SideBar.module.scss';
import { Props } from './SideBar.types';

const SideBar = ({ items }: Props) => {
  const history = useHistory();
  const params = useParams<{
    secondSection: string;
  }>();
  return (
    <div className={styles.container}>
      <div className={styles.linkContainer}>
        <Router history={history}>
          <Switch>
            <Route>
              {items.map((route, idx) => {
                const isCurrentRoute = route.id.includes(params.secondSection);
                return (
                  <div
                    key={route.label}
                    className={classnames(styles.link, {
                      [styles.active]: isCurrentRoute,
                    })}
                    onClick={() => {
                      history.push(route.id);
                    }}
                  >
                    <div className={styles.navNumber}>{`${idx + 1}.`}</div>
                    {`${route.label}`}
                  </div>
                );
              })}
            </Route>
          </Switch>
        </Router>
      </div>
    </div>
  );
};

export default SideBar;
