import * as z from 'zod';

import { AssessmentDetails, Question, QuestionFamily } from 'src/Services/API';

export interface Props {
  assessment: AssessmentDetails;
  questionFamily?: QuestionFamily;
  lastSectionId?: string;
  allQuestions: Question[];
}

export const validationSchema = z.object({
  responses: z.array(
    z.object({ id: z.string().optional(), value: z.string(), questionId: z.string() })
  ),
});

export const finalisationSchema = z.object({
  responses: z
    .array(z.object({ id: z.string().optional(), value: z.string(), questionId: z.string() }))
    .refine(
      responses => {
        if (responses.find(response => response.value === '') !== undefined) {
          return false;
        }
        return true;
      },
      { message: 'Vous avez des questions obligatoires non-renseignées' }
    ),
});

export type ValidationSchemaType = z.infer<typeof validationSchema>;
export type FinalisationSchemaType = z.infer<typeof finalisationSchema>;
