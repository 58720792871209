export enum FETCH_STATUS {
  PENDING = 'PENDING',
  FULFILLED = 'FULFILLED',
  REJECTED = 'REJECTED',
}

export enum ASSESSMENT_STATUS {
  InProgress = 'InProgress',
  Finished = 'Finished',
}
