import { UseMutationOptions, useMutation, useQueryClient } from '@tanstack/react-query';
import { useSelector } from 'react-redux';

import { getCurrentBrand } from 'src/Redux/Perimeter/Selectors';
import { CreateAssessmentBody, AssessmentsService } from 'src/Services/API';

import { MutationKeys, QueryKeys } from '../types';

export const useCreateAssessment = (
  extraOptions?: UseMutationOptions<string, unknown, Omit<CreateAssessmentBody, 'brandCode'>>
) => {
  const queryClient = useQueryClient();
  const currentBrand = useSelector(getCurrentBrand);
  const { onSuccess, ...rest } = extraOptions ?? {};
  return useMutation(
    [MutationKeys.createAssessment],
    async (assessment: Omit<CreateAssessmentBody, 'brandCode'>) => {
      if (!currentBrand?.brandCodeApiHeader) {
        throw new Error('No current brand or has an invalid current brand');
      }
      const assessmentId = await AssessmentsService.assessmentsControllerCreateAssessment({
        body: {
          ...assessment,
          brandCode: currentBrand.brandCodeApiHeader,
        },
      });
      return assessmentId;
    },
    {
      onSuccess: (...args) => {
        queryClient.invalidateQueries({ queryKey: [QueryKeys.fetchAssessments] });
        onSuccess?.(...args);
      },
      ...rest,
    }
  );
};
