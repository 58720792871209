import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';

import { getCurrentBrand } from 'src/Redux/Perimeter/Selectors';
import { AssessmentsService, Question, RepositoriesService } from 'src/Services/API';

import { AssessmentDetailsWithQuestionLabel, QueryKeys } from '../types';

export interface AssessmentDetailsWithQuestions extends AssessmentDetailsWithQuestionLabel {
  questions: Question[];
}

export const useFetchAssessmentByIdWithResponses = (
  assessmentId: string,
  extraOptions?: UseQueryOptions<
    AssessmentDetailsWithQuestions,
    unknown,
    AssessmentDetailsWithQuestions,
    (string | undefined)[]
  >
) => {
  const currentBrand = useSelector(getCurrentBrand);
  return useQuery(
    [QueryKeys.fetchAssessmentByIdWithResponses, assessmentId, currentBrand?.brandCodeApiHeader],
    async () => {
      if (!currentBrand || !currentBrand.brandCodeApiHeader || !currentBrand.agencies) {
        throw new Error('No current brand or has an invalid current brand');
      }
      const assessment = await AssessmentsService.assessmentsControllerGetAssessmentDetail({
        id: assessmentId,
        brandCode: currentBrand.brandCodeApiHeader,
      });
      const questionFamilies = await RepositoriesService.repositoriesControllerGetQuestionFamilies({
        assessmentTypeId: assessment.type?.id ?? '',
        brandCode: currentBrand.brandCodeApiHeader,
      });
      const questionMap = await Promise.all(
        questionFamilies.map(async family => {
          return {
            familyId: family.id,
            questions: await RepositoriesService.repositoriesControllerGetQuestions({
              questionFamiliesId: family.id ?? '',
              brandCode: currentBrand.brandCodeApiHeader ?? '',
            }),
          };
        })
      );
      const completeQuestionFamilies = await Promise.all(
        questionFamilies.map(questionFamily => {
          const questions = questionMap.find(map => map.familyId === questionFamily.id)?.questions;
          return {
            id: questionFamily.id,
            displayOrder: questionFamily.displayOrder,
            label: questionFamily.label,
            responses:
              questions?.map(question => {
                const responses = assessment.questionFamilies?.find(
                  family => family.id === questionFamily.id
                )?.responses;
                const response = responses?.find(response => response.questionId === question.id);
                const returnValue = response ?? {
                  value: '',
                  questionId: question.id,
                };
                return { ...returnValue, label: question.label, type: question.displayType };
              }) ?? [],
          };
        })
      );

      return {
        ...assessment,
        questionFamilies: completeQuestionFamilies,
        questions: questionMap.map(questionFamily => questionFamily.questions).flat(1),
      };
    },
    {
      refetchOnWindowFocus: false,
      ...extraOptions,
    }
  );
};
