import { FetchButton, TopButton } from '@randstad-lean-mobile-factory/react-components-core';
import {
  ErrorMessage,
  useFormWithZodResolver,
} from '@randstad-lean-mobile-factory/react-form-fields';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useController, useFormState } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import QuestionContainer from 'src/Components/QuestionContainer';
import { useFetchQuestions } from 'src/Hooks/Assessment/useFetchQuestions';
import { useUpdateAssessment } from 'src/Hooks/Assessment/useUpdateAssessment';
import { FormStatusContext } from 'src/Hooks/Navigation/useFormNavigationContextElements';
import { getCurrentAgency, getCurrentBrand } from 'src/Redux/Perimeter/Selectors';
import { FETCH_STATUS } from 'src/Redux/Types';
import {
  AssessmentStatus,
  OSMAssessmentModelDtoAssessmentResponseDto as ResponseType,
} from 'src/Services/API';
import { mergeFetchStatusWithFormState, toFetchStatus } from 'src/Services/Async';

import styles from './AssessmentModificationQuestions.module.scss';
import {
  finalisationSchema,
  FinalisationSchemaType,
  Props,
  validationSchema,
  ValidationSchemaType,
} from './AssessmentModificationQuestions.types';

enum ButtonType {
  VALIDATE,
  FINISH,
}

const AssessmentModificationQuestions = ({
  assessment,
  questionFamily,
  lastSectionId,
  allQuestions,
}: Props) => {
  const history = useHistory();
  const currentAgency = useSelector(getCurrentAgency);
  const currentBrand = useSelector(getCurrentBrand);
  const fetchQuestions = useFetchQuestions(questionFamily?.id ?? '');

  const sortedData = fetchQuestions.data?.sort((a, b) => {
    const orderA = parseInt(a.displayOrder ?? '', 10);
    const orderB = parseInt(b.displayOrder ?? '', 10);
    return orderA - orderB;
  });

  let responses = [] as ResponseType[];
  assessment.questionFamilies?.forEach(family => {
    responses = responses.concat(family.responses ?? []);
  });

  const mandatoryQuestions = allQuestions
    .filter(question => question.displayType === 'R5G')
    .map(question => {
      return question.id;
    });

  const mandatoryResponses = responses.filter(response =>
    mandatoryQuestions?.includes(response.questionId)
  );

  const {
    control,
    handleSubmit,
    reset,
    formState: { isSubmitting, isSubmitted, isSubmitSuccessful, isDirty },
  } = useFormWithZodResolver({
    schema: validationSchema,
    defaultValues: {
      responses: responses.map(response => ({
        value: response.value,
        questionId: response.questionId,
      })),
    },
  });

  const mandatoryResponsesZodResolver = useFormWithZodResolver({
    schema: finalisationSchema,
    defaultValues: {
      responses: mandatoryResponses.map(response => ({
        value: response.value,
        questionId: response.questionId,
      })),
    },
  });

  const formStatusContext = useContext(FormStatusContext);
  useEffect(() => {
    formStatusContext.setHasFormBeenTouched(isDirty);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty]);

  const mandatoryResponsesFormState = useFormState({
    control: mandatoryResponsesZodResolver.control,
  });

  const responsesController = useController({
    name: 'responses',
    control,
  });

  const mandatoryResponsesController = useController({
    name: 'responses',
    control: mandatoryResponsesZodResolver.control,
  });

  const updateAssessmentMutationsValidate = useUpdateAssessment();
  const updateAssessmentMutationsFinish = useUpdateAssessment();
  const fetchStatusValidate = toFetchStatus(updateAssessmentMutationsValidate);
  const fetchStatusFinish = toFetchStatus(updateAssessmentMutationsFinish);

  const [buttonType, setButtonType] = useState(ButtonType.VALIDATE);
  const containerRef = useRef<HTMLDivElement>(null);
  const scrollToTop = () => containerRef.current?.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

  const onClick = (finished = false) => {
    const handleSubmitSuccess = (values: ValidationSchemaType) => {
      const body = {
        id: assessment.id ?? '',
        assessment: {
          assessmentTypeId: assessment.type?.id ?? '',
          status: finished
            ? AssessmentStatus.Finished
            : assessment.status ?? AssessmentStatus.Finished,
          companyId: assessment.company?.id ?? '',
          agencyId: currentAgency ?? '',
          contactIds: assessment.contacts?.map(contact => contact.id ?? '') ?? [],
          brandCode: currentBrand?.brand?.code ?? '',
          responses: values.responses,
        },
      };
      finished
        ? updateAssessmentMutationsFinish.mutate(body)
        : updateAssessmentMutationsValidate.mutate(body);
    };
    setButtonType(finished ? ButtonType.FINISH : ButtonType.VALIDATE);
    if (finished) {
      setButtonType(ButtonType.FINISH);
      mandatoryResponsesZodResolver.handleSubmit((values: FinalisationSchemaType) =>
        handleSubmitSuccess(values)
      )();
    } else {
      setButtonType(ButtonType.VALIDATE);
      handleSubmit((values: ValidationSchemaType) => {
        handleSubmitSuccess(values);
      })();
    }
  };

  const currentPageMandatoryQuestions = sortedData?.filter(
    question => question.displayType === 'R5G'
  );
  const isMandatoryEmptyQuestion = currentPageMandatoryQuestions
    ?.map(
      question =>
        mandatoryResponsesController.field.value.find(
          (response: ResponseType) => response.questionId === question.id
        )?.value === ''
    )
    .includes(true);

  useEffect(() => {
    formStatusContext.updateValidationStatus({
      handleSubmit: () =>
        handleSubmit((values: ValidationSchemaType) => {
          updateAssessmentMutationsValidate.mutate({
            id: assessment.id ?? '',
            assessment: {
              assessmentTypeId: assessment.type?.id ?? '',
              status: assessment.status ?? AssessmentStatus.Finished,
              companyId: assessment.company?.id ?? '',
              agencyId: currentAgency ?? '',
              contactIds: assessment.contacts?.map(contact => contact.id ?? '') ?? [],
              brandCode: currentBrand?.brand?.code ?? '',
              responses: values.responses,
            },
          });
        }),
      fetchStatus: fetchStatusValidate,
      formStateElements: { isSubmitSuccessful, isSubmitted, isSubmitting },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitted, isSubmitting, isSubmitSuccessful, fetchStatusValidate]);

  return (
    <>
      {fetchQuestions.isSuccess && (
        <div className={styles.container} ref={containerRef}>
          <div className={styles.route}>
            <div className={styles.routeNumber}>{`${parseInt(
              questionFamily?.displayOrder ?? '',
              10
            )}.`}</div>
            <div className={styles.routeTitle}>
              {questionFamily?.label?.toLowerCase() ?? 'error'}
            </div>
          </div>
          {questionFamily &&
            sortedData?.map(question => {
              const response = responsesController.field.value.find(
                (response: ResponseType) => response.questionId === question.id
              );

              const onChange = (value: string) => {
                const responses = responsesController.field.value.filter(
                  (response: ResponseType) => response.questionId !== question.id
                );
                const newValues = [...responses, { value: value, questionId: question.id }];
                responsesController.field.onChange(newValues);
                if (mandatoryQuestions.includes(question.id)) {
                  const mandatoryResponses = mandatoryResponsesController.field.value.filter(
                    (response: ResponseType) => response.questionId !== question.id
                  );
                  const newMandatoryValues = [
                    ...mandatoryResponses,
                    { value: value, questionId: question.id },
                  ];
                  mandatoryResponsesController.field.onChange(newMandatoryValues);
                }
              };
              return (
                <QuestionContainer
                  key={question.id ?? ''}
                  questionId={question.id ?? ''}
                  label={question.label ?? ''}
                  type={question.displayType ?? ''}
                  response={response}
                  onChange={onChange}
                  disabled={assessment.status === AssessmentStatus.Finished}
                />
              );
            })}
          <div className={styles.twoBtn}>
            <FetchButton
              secondary
              className={styles.validationButton}
              onClick={() => onClick()}
              fetchStatus={
                buttonType === ButtonType.VALIDATE
                  ? mergeFetchStatusWithFormState(fetchStatusValidate, {
                      isSubmitSuccessful,
                      isSubmitted,
                      isSubmitting,
                    })
                  : FETCH_STATUS.FULFILLED
              }
              onSuccess={() => reset({ responses: responsesController.field.value })}
              title="valider cette étape"
              errorTitle="réessayer"
              disabled={assessment.status === AssessmentStatus.Finished}
            />
            {questionFamily?.id === lastSectionId && (
              <FetchButton
                onClick={() => onClick(true)}
                fetchStatus={
                  buttonType === ButtonType.FINISH
                    ? mergeFetchStatusWithFormState(fetchStatusFinish, {
                        isSubmitSuccessful: mandatoryResponsesFormState.isSubmitSuccessful,
                        isSubmitted: mandatoryResponsesFormState.isSubmitted,
                        isSubmitting: mandatoryResponsesFormState.isSubmitting,
                      })
                    : FETCH_STATUS.FULFILLED
                }
                title={`terminer le ${assessment.type?.label?.toLowerCase() ?? 'bilan'}`}
                errorTitle="réessayer"
                disabled={assessment.status === AssessmentStatus.Finished}
                onSuccess={() => buttonType === ButtonType.FINISH && history.push('/')}
              />
            )}
          </div>
          {(isMandatoryEmptyQuestion || questionFamily?.id === lastSectionId) && (
            <ErrorMessage error={mandatoryResponsesController.fieldState.error} />
          )}
        </div>
      )}
      <TopButton onClick={scrollToTop} />
    </>
  );
};

export default AssessmentModificationQuestions;
