import { Button, PopupMenu } from '@randstad-lean-mobile-factory/react-components-core';
import { Email, ThreeDots } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import classnames from 'classnames';
import React, { useContext, useEffect, useState } from 'react';

import PdfWarningModal from 'src/Containers/Modals/PdfWarningModal';
import { useFetchAssessmentById } from 'src/Hooks/Assessment/useFetchAssessmentById';
import { useSendAssessmentMail } from 'src/Hooks/Assessment/useSendAssessmentMail';
import { useFetchCompanyContacts } from 'src/Hooks/Companies/useFetchCompanyContacts';
import { FormStatusContext } from 'src/Hooks/Navigation/useFormNavigationContextElements';
import { AssessmentStatus } from 'src/Services/API';

import PDFGenerationButton from '../PDFGenerationButton';

import styles from './DetailButton.module.scss';
import { Props } from './DetailButton.types';

const DetailButton = ({ assessmentId, companyId }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [assessmentMails, setAssessmentMails] = useState<string[]>([]);
  const mailMutation = useSendAssessmentMail();
  const assessmentQuery = useFetchAssessmentById(assessmentId ?? '');
  const companyContactQuery = useFetchCompanyContacts(companyId ?? '');
  const assessment = useFetchAssessmentById(assessmentId ?? '');
  useEffect(
    () =>
      setAssessmentMails(
        companyContactQuery.data
          ?.filter(
            contact =>
              assessmentQuery.data?.contacts
                ?.map(assessmentContact => {
                  return assessmentContact.id;
                })
                .includes(contact.contactId) && contact.email !== undefined
          )
          .map(contact => {
            return contact.email ?? '';
          }) ?? []
      ),
    [assessmentQuery.data, companyContactQuery.data]
  );
  const formStatusContext = useContext(FormStatusContext);
  return (
    <>
      <PdfWarningModal
        open={formStatusContext.pdfWarningModalState}
        onClose={() => formStatusContext.closePdfWarningModal()}
        assessmentId={assessmentId}
        companyId={companyId}
      />
      <PopupMenu
        position="bottom right"
        width={200}
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        trigger={
          <Button.Tertiary
            className={classnames(styles.trigger, { [styles.activeTrigger]: isOpen })}
          >
            <ThreeDots className={styles.icon} />
          </Button.Tertiary>
        }
      >
        <PDFGenerationButton assessmentId={assessmentId} companyId={companyId} />
        <PopupMenu.Item
          text="envoyer par mail"
          onClick={() => mailMutation.mutate(assessmentId ?? '')}
          disabled={
            assessmentMails.length === 0 ||
            !assessmentMails[0].includes('@') ||
            assessment.data?.status !== AssessmentStatus.Finished
          }
          icon={<Email />}
        />
      </PopupMenu>
    </>
  );
};

export default DetailButton;
