import { ExclusiveSelectionGroup } from '@randstad-lean-mobile-factory/react-components-core';
import React, { useEffect, useState } from 'react';

import styles from './R6Component.module.scss';
import { Props } from './R6Component.types';

const R6Component = ({ label, value, onChange, disabled, canBeReset }: Props) => {
  const toggleItems = Array(2)
    .fill(undefined)
    .map((_, index) => (index === 0 ? '1' : '0'));
  const noteItems = Array(4)
    .fill(undefined)
    .map((_, index) => (index === 3 ? 'NS' : (index + 1).toString()));
  const [acceptance, setAcceptance] = useState(value.split(';')[0] ?? '');
  const [rating, setRating] = useState(value.split(';')[1] ?? '');
  const setValue = (a: string, b: string) => {
    onChange(`${a};${b}`);
  };
  useEffect(() => {
    setAcceptance(value.split(';')[0] ?? '');
    setRating(value.split(';')[1] ?? '');
  }, [value]);
  return (
    <div className={styles.container}>
      <div className={styles.label}>{label}</div>
      <div className={styles.question}>
        <div className={styles.toggle}>
          <ExclusiveSelectionGroup
            selected={acceptance}
            values={toggleItems}
            getKey={key => key}
            getStringValue={value => (parseInt(value, 10) === 1 ? 'oui' : 'non')}
            setSelected={value => setValue(value ?? '', rating)}
            disabled={disabled}
            canBeReset={canBeReset}
          />
        </div>
        <div className={styles.separator} />
        <div className={styles.note}>
          <ExclusiveSelectionGroup
            dense
            selected={rating}
            values={noteItems}
            getKey={key => key}
            getStringValue={value => value}
            setSelected={value => setValue(acceptance, value ?? '')}
            disabled={disabled}
            canBeReset={canBeReset}
          />
        </div>
      </div>
    </div>
  );
};

export default R6Component;
