// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface Props {}

interface AssessmentNavigationNode {
  name: string;
  default: string;
  path: string;
}

type AssessmentNav = { [key: string]: AssessmentNavigationNode };

const baseRoute = '/company/:companyId/assessment/:assessmentId/assessmentType/:assessmentType';

export const getNavigation = (type: string): AssessmentNav => {
  return {
    content: {
      name: type.toLowerCase(),
      default: './content',
      path: `${baseRoute}/content`,
    },
    /*
  KPI: {
    name: 'KPI',
    default: './KPI',
    path: `${baseRoute}/KPI`,
  },
  */
  };
};

export enum FirstSection {
  CONTENT = 'content',
  KPI = 'KPI',
}
