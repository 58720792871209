import { toast } from '@randstad-lean-mobile-factory/react-components-core';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { useSelector } from 'react-redux';

import { getCurrentBrand } from 'src/Redux/Perimeter/Selectors';
import { AssessmentsService } from 'src/Services/API';

import { MutationKeys } from '../types';

export const useSendAssessmentMail = (
  extraOptions?: UseMutationOptions<void, unknown, unknown>
) => {
  const currentBrand = useSelector(getCurrentBrand);
  return useMutation(
    [MutationKeys.sendAssessmentByMail],
    async (assessmentId: string) => {
      if (!currentBrand || !currentBrand.brandCodeApiHeader || !currentBrand.agencies) {
        return Promise.reject('No current brand or has an invalid current brand');
      }
      await AssessmentsService.assessmentsControllerSendAssessmentEmail({
        id: assessmentId,
        body: {
          brandCode: currentBrand.brandCodeApiHeader,
        },
      });
    },
    {
      onError: () => {
        toast.error("une erreur interne empêche l'envoi de l'email");
      },
      onSuccess: () => {
        toast.success('message envoyé avec succès');
      },
      ...extraOptions,
    }
  );
};
