import {
  Button,
  FetchButton,
  Modal,
  SelectionIconButton,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import {
  ErrorMessage as ZodError,
  useFormWithZodResolver,
} from '@randstad-lean-mobile-factory/react-form-fields';
import React, { useCallback, useEffect } from 'react';
import ContentLoader from 'react-content-loader';
import { useController } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import {
  DefaultIcon,
  IllusAssessmentCreation,
  TypeBuyerCustomer,
  TypeFirst,
  TypeHRCustomer,
  TypeProductionCustomer,
  TypeRecruiting,
  TypeSatisfaction,
} from 'src/Assets';
import ContactPicker from 'src/Components/ContactPicker';
import { ErrorMessage } from 'src/Components/ErrorMessage';
import { useCreateAssessment } from 'src/Hooks/Assessment/useCreateAssessment';
import { useFetchAssessmentTypes } from 'src/Hooks/Assessment/useFetchAssessmentTypes';
import { useFetchCompanyDetails } from 'src/Hooks/Companies';
import { useFetchCompanyContacts } from 'src/Hooks/Companies/useFetchCompanyContacts';
import { getCurrentAgency, getCurrentBrand } from 'src/Redux/Perimeter/Selectors';
import { toFetchStatus } from 'src/Services/Async';
import { AssessmentCreationState, getEditionRoute } from 'src/Services/Routing';

import styles from './AssessmentCreationModal.module.scss';
import { assessmentCreationSchema } from './AssessmentCreationModal.schema';

const iconMapping: { [id: string]: JSX.Element } = {
  'LSB-MVeJ70VfIk3YSGmDFst3mEuZNFxhldjZZ0Y1qMA1': <TypeSatisfaction />,
  Auj5wsHpgrFuaRn0SvugbMt3mEuZNFxhldjZZ0Y1qMA1: <TypeSatisfaction />,
  A8vYtjXYGMOw1bzT2Erjfct3mEuZNFxhldjZZ0Y1qMA1: <TypeSatisfaction />,
  '0B_1O590Z5sbNosybkwFZct3mEuZNFxhldjZZ0Y1qMA1': <TypeSatisfaction />,
  hCRT1sU08q_2MCaUh8KIect3mEuZNFxhldjZZ0Y1qMA1: <TypeFirst />,
  '-ex0XqAI4UmjVXvvR7uE7Mt3mEuZNFxhldjZZ0Y1qMA1': <TypeRecruiting />,
  '0oi_vwz7fab6Bhc5ap4Axct3mEuZNFxhldjZZ0Y1qMA1': <TypeRecruiting />,
  '-7Ekzk8UYJpa1YlPGvYm7k8t3mEuZNFxhldjZZ0Y1qMA1': <TypeRecruiting />,
  ezegFL4WdZTUShxWx4g4Y8t3mEuZNFxhldjZZ0Y1qMA1: <TypeBuyerCustomer />,
  '-ASHSw29JVqJHJEVH1f5BMt3mEuZNFxhldjZZ0Y1qMA1': <TypeHRCustomer />,
  lhagLITQDZ0AdHsSZHroTMt3mEuZNFxhldjZZ0Y1qMA1: <TypeProductionCustomer />,
  MNOLhQUPuQ0r0RBa1urkRct3mEuZNFxhldjZZ0Y1qMA1: <TypeProductionCustomer />,
};

const gridElementSize = (elementNumber: number): string => {
  const unitSize = (35 - (elementNumber - 1)) / elementNumber;
  return new Array(elementNumber).fill(`${unitSize}rem`).join(' ');
};

const AssessmentCreationModal = () => {
  const params = new URLSearchParams(window.location.search);
  const currentAgency = useSelector(getCurrentAgency);
  const currentBrand = useSelector(getCurrentBrand);
  const location = useLocation<AssessmentCreationState | undefined>();
  const history = useHistory();
  const onClose = useCallback(() => {
    history.replace(location.pathname.replace('/create-assessment', ''));
  }, [history, location.pathname]);
  const createAssessmentMutation = useCreateAssessment();
  const assessmentTypeQuery = useFetchAssessmentTypes();

  const { data, isSuccess, isError, isLoading } = useFetchCompanyContacts(
    location.state?.modalParameters?.companyId ?? ''
  );

  const { control, handleSubmit, setValue } = useFormWithZodResolver({
    schema: assessmentCreationSchema,
    defaultValues: {
      selectedAssessmentType: undefined,
      selectedContactId: data?.find(contact => contact.mainContact)?.contactId ?? '',
    },
  });

  const companyDetailsQuery = useFetchCompanyDetails(params.get('company') ?? '', {
    enabled: !location.state?.modalParameters?.companyName ?? false,
  });
  const companyName =
    location.state?.modalParameters?.companyName ?? companyDetailsQuery.data?.companyName;

  const selectedAssessmentTypeController = useController({
    name: 'selectedAssessmentType',
    control,
  });

  const selectedContactController = useController({
    control,
    name: 'selectedContactId',
  });

  useEffect(() => {
    setValue(
      'selectedContactId',
      params.get('contact') ?? data?.find(contact => contact.mainContact)?.contactId ?? ''
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, setValue]);
  return (
    <Modal
      open
      iconClassName={styles.modalIcon}
      icon={<IllusAssessmentCreation />}
      title={`nouveau bilan ${currentBrand?.brand?.name?.toLowerCase() ?? ''}`}
      onClose={onClose}
      footerActionsRight={[
        <Button.Secondary onClick={onClose}>annuler</Button.Secondary>,
        <FetchButton
          title="valider"
          fetchStatus={toFetchStatus(createAssessmentMutation)}
          onClick={handleSubmit(() => {
            createAssessmentMutation.mutate({
              companyId: location.state?.modalParameters?.companyId ?? '',
              contactId: selectedContactController.field.value,
              assessmentTypeId: selectedAssessmentTypeController.field.value.id,
              agencyId: currentAgency ?? '',
            });
          })}
          onSuccess={() =>
            history.push(
              getEditionRoute({
                companyId: location.state?.modalParameters?.companyId ?? '',
                assessmentId: createAssessmentMutation.data,
                section: 'content',
              }),
              { assessmentType: selectedAssessmentTypeController.field.value.label }
            )
          }
        />,
      ]}
    >
      <WithLightTitle title="nom du client choisi">
        <div className={styles.companyName}>
          {(location.state?.modalParameters?.companyName || companyDetailsQuery.isSuccess) && (
            <span>{companyName}</span>
          )}
          {!location.state?.modalParameters?.companyName && companyDetailsQuery.isLoading && (
            <ContentLoader height="0.7rem" uniqueKey="companyName" backgroundColor="#d7d7d7">
              <rect x="0%" y="0%" rx="4" ry="4" width="100%" height="100%" />
            </ContentLoader>
          )}
          {!location.state?.modalParameters?.companyName && companyDetailsQuery.isError && (
            <ErrorMessage message="Une erreur est survenue lors de la récupération du nom du compte" />
          )}
        </div>
      </WithLightTitle>
      <WithLightTitle title="quel type de bilan souhaitez-vous ?">
        <div
          className={styles.gridContainer}
          style={{ gridTemplateColumns: gridElementSize(assessmentTypeQuery.data?.length ?? 3) }}
        >
          {assessmentTypeQuery.isLoading &&
            new Array(3).fill(undefined).map((_, index) => {
              return <SelectionIconButton.Loader key={index} />;
            })}
          {assessmentTypeQuery.isSuccess &&
            assessmentTypeQuery.data?.map(assessmentType => (
              <SelectionIconButton
                key={assessmentType.id}
                className={styles.gridItem}
                labelClassName={styles.gridItemLabel}
                label={
                  assessmentType.label?.includes('client')
                    ? assessmentType.label?.split('Bilan de satisfaction ').pop() ?? ''
                    : assessmentType.label?.split('Bilan de ').pop() ?? ''
                }
                icon={iconMapping[assessmentType.id ?? ''] ?? <DefaultIcon />}
                selected={assessmentType.id === selectedAssessmentTypeController.field.value?.id}
                onClick={() =>
                  selectedAssessmentTypeController.field.onChange({
                    id: assessmentType.id,
                    label: assessmentType.label,
                  })
                }
              />
            ))}
        </div>
        <ZodError error={selectedAssessmentTypeController.fieldState.error} />
      </WithLightTitle>
      <WithLightTitle title="choisissez un contact pour ce bilan" className={styles.contactPicker}>
        {isSuccess && (
          <ContactPicker
            contacts={data ?? []}
            fetchStatus={toFetchStatus({ isSuccess, isError, isLoading })}
            onChange={contact => selectedContactController.field.onChange(contact?.contactId)}
            selectedContactId={selectedContactController.field.value}
          />
        )}
        {isLoading && (
          <ContentLoader
            height="4rem"
            width="100%"
            uniqueKey="contactpicker"
            backgroundColor="#d7d7d7"
          >
            <rect x="0%" y="0%" rx="4" ry="4" width="100%" height="100%" />
          </ContentLoader>
        )}
        {isError && (
          <ErrorMessage message="Une erreur est survenue lors de la récupération des contacts du compte" />
        )}
        <ZodError error={selectedContactController.fieldState.error} />
      </WithLightTitle>
    </Modal>
  );
};

export default AssessmentCreationModal;
