/* eslint-disable @typescript-eslint/no-explicit-any */
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import { AssessmentCreationState, LocationState } from 'src/Services/Routing';

export type ModalsParamList = {
  'perimeter-selection': LocationState;
  'create-assessment': AssessmentCreationState;
};

export type ModalName = keyof ModalsParamList;
export type ModalNameParamExtending<BaseState> = {
  [K in ModalName]: ModalsParamList[K] extends BaseState ? K : never;
}[ModalName];

export function useModal<T extends ModalName = ModalName>(modalName: T) {
  const history = useHistory();
  const { pathname, search } = useLocation();
  return {
    open: (state: ModalsParamList[T]) => {
      history.push({
        pathname: pathname !== '/' ? `${pathname}/${modalName}` : modalName,
        state: state,
        search: search,
      });
    },
  };
}

export function useModalWithLocation<
  T extends ModalNameParamExtending<LocationState<any>> = ModalNameParamExtending<
    LocationState<any>
  >
>(modalName: T) {
  const location = useLocation();
  const { open } = useModal(modalName);
  return {
    open: (state: Omit<ModalsParamList[T], 'background'>) => {
      open({ background: location, ...state });
    },
  };
}
