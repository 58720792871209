import {
  Assessment,
  AssessmentDetails,
  OSMCompanyModelCompanyDtoCompanyAddressDto,
} from 'src/Services/API';

export enum QueryKeys {
  fetchCompanies = 'fetchCompanies',
  fetchPerimeter = 'fetchPerimeter',
  fetchAssessmentTypes = 'fetchAssessmentTypes',
  fetchCompanyDetails = 'fetchCompanyDetails',
  fetchCompanyContacts = 'fetchCompanyContacts',
  fetchAssessments = 'fetchAssessments',
  fetchAssessmentById = 'fetchAssessmentById',
  fetchAssessmentByIdWithResponses = 'fetchAssessmentByIdWithResponses',
  fetchQuestions = 'fetchQuestions',
  fetchQuestionFamilies = 'fetchQuestionFamilies',
}

export enum MutationKeys {
  createAssessment = 'createAssessment',
  updateAssessment = 'updateAssessment',
  generateAssessmentPdf = 'generateAssessmentPdf',
  sendAssessmentByMail = 'sendAssessmentByMail',
}

interface AssessmentResponseWithLabel {
  id?: string;
  value?: string;
  questionId?: string;
  label?: string;
  type?: string;
}

export interface AssessmentWithCompanyAddress extends Assessment {
  companyAddress?: OSMCompanyModelCompanyDtoCompanyAddressDto;
}

interface QuestionFamilyWithQuestionLabel {
  id?: string;
  label?: string;
  displayOrder?: string;
  responses?: AssessmentResponseWithLabel[];
}

export interface AssessmentDetailsWithQuestionLabel
  extends Omit<AssessmentDetails, 'questionFamilies'> {
  questionFamilies?: QuestionFamilyWithQuestionLabel[];
}
