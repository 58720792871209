import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { useSelector } from 'react-redux';

import { getCurrentBrand } from 'src/Redux/Perimeter/Selectors';
import {
  AssessmentType,
  OSMConsultantModelDtoConsultantAgencyByCompany,
  RepositoriesService,
} from 'src/Services/API';

import { QueryKeys } from '../types';

export const useFetchAssessmentTypes = (
  extraOptions?: UseQueryOptions<
    AssessmentType[],
    unknown,
    AssessmentType[],
    (QueryKeys | OSMConsultantModelDtoConsultantAgencyByCompany | undefined)[]
  >
) => {
  const currentBrand = useSelector(getCurrentBrand);
  return useQuery(
    [QueryKeys.fetchAssessmentTypes, currentBrand],
    async () => {
      if (!currentBrand || !currentBrand.brandCodeApiHeader) {
        return Promise.reject('No current brand or has an invalid current brand');
      }
      return RepositoriesService.repositoriesControllerGetAssessmentTypes({
        brandCode: currentBrand.brandCodeApiHeader,
      });
    },
    { ...extraOptions }
  );
};
