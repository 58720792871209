import { ToastContainer } from '@randstad-lean-mobile-factory/react-components-core';
import React, { useEffect } from 'react';
import { Router, Route, Switch, useLocation, useHistory } from 'react-router-dom';

import history from 'src/Utils/history';

import PrivateRoute from './Components/PrivateRoute';
import Login from './Containers/Login';
import MainPage from './Containers/MainPage';
import AssessmentCreationModal from './Containers/Modals/AssessmentCreationModal';
import PerimeterSelectionModal from './Containers/PerimeterSelectionModal';
import useAuth from './Hooks/Authentication/useAuth';
import { setupAPI } from './Services/API/APIConfigurator';
import { security } from './Services/API/Interceptors/security';
import { LocationState, ModalRoute } from './Services/Routing';
import DebugTools from './Utils/DebugTools';

const modalRouteMap: ModalRoute[] = [
  { route: 'perimeter-selection', component: PerimeterSelectionModal },
  { route: 'create-assessment', component: AssessmentCreationModal },
];

function ModalSwitch() {
  const location = useLocation<LocationState>();
  const locationState = location.state;
  const background = locationState?.background;
  const history = useHistory();

  if (
    !background &&
    modalRouteMap.some(
      modalRoute => modalRoute.route === location.pathname && !modalRoute.backgroundIsOptional
    )
  ) {
    history.push('/');
  }

  return (
    <DebugTools>
      <Switch location={background || location}>
        <Route exact path="/login" component={Login} />
        <PrivateRoute key={1000} path="/" component={MainPage} />
      </Switch>

      {modalRouteMap.map(modalInfo => (
        <PrivateRoute
          key={modalInfo.route}
          path={`(.*)/${modalInfo.route}`}
          children={<modalInfo.component modalParameters={locationState?.modalParameters} />}
        />
      ))}
      <ToastContainer position="bottom-right" />
    </DebugTools>
  );
}

function App() {
  const { getAccessTokenSilently, logout } = useAuth();
  security.setAccessTokenSilently(getAccessTokenSilently);
  security.setLogout(logout);
  useEffect(() => setupAPI(), []);
  return (
    <div className="App">
      <Router history={history}>
        <ModalSwitch />
      </Router>
    </div>
  );
}

export default App;
