import classnames from 'classnames';
import React from 'react';
import { useRef, useEffect } from 'react';

import styles from './SegmentedMenuItem.module.scss';
import { Props } from './SegmentedMenuItem.types';

const SegmentedMenuItem = ({ isSelected, label, onSelect, setBoundingRect }: Props) => {
  const ref = useRef<HTMLSpanElement>(null);

  const boundingClientRect = ref.current?.getBoundingClientRect();
  useEffect(() => {
    const handleResize = () => {
      const currentBoundingClientRect = ref.current?.getBoundingClientRect();
      if (currentBoundingClientRect && isSelected) {
        setBoundingRect(currentBoundingClientRect);
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSelected, boundingClientRect?.width, boundingClientRect?.left, boundingClientRect?.bottom]);

  return (
    <label className={styles.tabItemContainer}>
      <span className={classnames({ [styles.selectedText]: isSelected })} ref={ref}>
        {label}
      </span>
      <input
        className={styles.input}
        type="radio"
        name="radioBtn"
        onChange={() => {
          onSelect();
        }}
      />
    </label>
  );
};

export default SegmentedMenuItem;
