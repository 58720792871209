import React, { useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { useFetchAssessmentById } from 'src/Hooks/Assessment/useFetchAssessmentById';
import { FormStatusContext } from 'src/Hooks/Navigation/useFormNavigationContextElements';
import { FETCH_STATUS } from 'src/Redux/Types';

import styles from './BreadCrumb.module.scss';

const BreadCrumb = () => {
  const history = useHistory();
  const params = useParams<{
    assessmentId: string;
  }>();
  const formStatusContext = useContext(FormStatusContext);
  const { data } = useFetchAssessmentById(params.assessmentId);
  const company = data?.company;

  const items = [
    {
      title: 'Accueil',
      onClick: () => {
        if (formStatusContext.hasFormBeenTouched) {
          formStatusContext.openNavWarningModal({ destination: '/' });
          formStatusContext.setFetchStatus(FETCH_STATUS.FULFILLED);
        } else {
          history.push('/');
        }
      },
    },
  ];

  if (company) {
    const { label, id } = company;
    items.push({
      title: `${label}`,
      onClick: () => {
        if (formStatusContext.hasFormBeenTouched) {
          formStatusContext.openNavWarningModal({ destination: `/company/${id}` });
          formStatusContext.setFetchStatus(FETCH_STATUS.FULFILLED);
        } else {
          history.push({
            pathname: `/company/${id}`,
            state: {
              routeParameters: {
                companyId: id,
                companyName: label,
              },
            },
          });
        }
      },
    });
  }

  return (
    <div className={styles.container}>
      {items.map(({ title, onClick }, index) => {
        return [
          <button key={title} className={styles.button} onClick={onClick}>
            <p>{title}</p>
          </button>,
          index === items.length - 1 ? null : (
            <p key={`separator-${index}`} className={styles.separator}>
              /
            </p>
          ),
        ];
      })}
    </div>
  );
};

export default BreadCrumb;
