import { Notebook } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import React from 'react';
import { useHistory } from 'react-router-dom';

import CompanyPicker from 'src/Components/CompanyPicker';
import { useAuthenticatedUser } from 'src/Hooks/Authentication/useAuthenticatedUser';

import styles from './Home.module.scss';
import LastAssessments from './LastAssessments';

const Home = () => {
  const { givenName } = useAuthenticatedUser();
  const history = useHistory();

  return (
    <div className={styles.container}>
      <Notebook className={styles.icon} />
      <div className={styles.searchContainer}>
        <div className={styles.hello}>Bonjour {givenName} !</div>
        <div className={styles.title}>créer ou retrouver un bilan</div>
        <div className={styles.subtitle}>de quel compte s'agit-il ?</div>
        <CompanyPicker
          className={styles.companyPicker}
          onCompanyChange={company =>
            history.push({
              pathname: `/company/${company.companyId}`,
              state: {
                routeParameters: {
                  companyId: company.companyId,
                  companyName: company.companyName,
                },
              },
            })
          }
        />
      </div>
      <LastAssessments />
    </div>
  );
};

export default Home;
