import classnames from 'classnames';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import AssessmentActions from 'src/Components/AssessmentActions';
import DisplayModeSelector from 'src/Components/DisplayModeSelector';
import { ErrorMessage } from 'src/Components/ErrorMessage';
import { useFetchAssessments } from 'src/Hooks/Assessment/useFetchAssessments';
import { ASSESSMENT_STATUS } from 'src/Redux/Types';
import { getCardDisplayMode } from 'src/Redux/UserPreferences/Selectors';
import { userPreferencesActions } from 'src/Redux/UserPreferences/Slice';
import { DISPLAY_MODE } from 'src/Redux/UserPreferences/Types';
import { getEditionRoute } from 'src/Services/Routing';
import { toDate } from 'src/Utils/dateTransformers';

import GridCard from './GridCard';
import styles from './LastAssessments.module.scss';
import ListCard from './ListCard';

const LastAssessments = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  // Very big screen (31inch) is approximatively 3 rows of 8 cards
  const assessmentsQuery = useFetchAssessments({ limit: 24 });
  const assessments = assessmentsQuery.data;
  const displayMode = useSelector(getCardDisplayMode);
  const onDisplayModeChange = useCallback(
    (displayMode: DISPLAY_MODE) => {
      dispatch(userPreferencesActions.setCardDisplayMode(displayMode));
    },
    [dispatch]
  );

  const CardOrGrid = displayMode === DISPLAY_MODE.GRID ? GridCard : ListCard;
  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <p className={styles.title}>derniers bilans :</p>
        <DisplayModeSelector
          displayMode={displayMode}
          onDisplayModeIndexUpdate={onDisplayModeChange}
        />
      </div>
      <div
        className={classnames({
          [styles.grid]: true,
          [styles.assessments]: displayMode,
        })}
      >
        {assessmentsQuery.isSuccess &&
          !assessmentsQuery.isFetching &&
          assessments?.pages.map(assessmentsGroup =>
            assessmentsGroup.map(assessment => {
              const contact =
                assessment.contacts?.[0] &&
                `${assessment.contacts?.[0].firstName ?? ''} ${
                  assessment.contacts?.[0].name ?? ''
                } `;
              return (
                <CardOrGrid
                  key={assessment.id}
                  type={assessment.type?.label ?? 'Type de bilan non précisé'}
                  contact={contact ?? 'Aucun contact pour ce bilan'}
                  updatedAt={
                    assessment.assessmentDate ? toDate(assessment.assessmentDate) : undefined
                  }
                  companyName={assessment.company?.label ?? 'Aucun nom pour ce bilan'}
                  companyAddress={`${assessment.companyAddress?.postalCode} - ${assessment.companyAddress?.city}`}
                  status={assessment.status as ASSESSMENT_STATUS | undefined}
                  onClick={() => {
                    history.push(
                      getEditionRoute({
                        companyId: assessment.company?.id,
                        assessmentId: assessment.id,
                        section: 'content',
                      }),
                      { assessmentType: assessment.type?.label }
                    );
                  }}
                  rightActions={
                    <AssessmentActions
                      companyId={assessment.company?.id}
                      assessmentId={assessment.id}
                    />
                  }
                />
              );
            })
          )}
        {assessmentsQuery.isFetching && (
          <>
            {new Array(24).fill(undefined).map((_, index) => (
              <GridCard.Loading key={`homepositionstudycard-${index}`} />
            ))}
          </>
        )}
        {assessmentsQuery.isError && (
          <ErrorMessage message="Une erreur est survenue dans la récupération des bilans de satisfaction" />
        )}
      </div>
    </div>
  );
};

export default LastAssessments;
