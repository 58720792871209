import { Loader } from '@randstad-lean-mobile-factory/react-components-core';
import { IllusEscalierYellow } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { useFetchAssessmentByIdWithResponses } from 'src/Hooks/Assessment/useFetchAssessmentByIdWithResponses';
import { useFetchQuestionFamilies } from 'src/Hooks/Assessment/useFetchQuestionFamilies';
import { FETCH_STATUS } from 'src/Redux/Types';
import { mergeFetchStatus, toFetchStatus } from 'src/Services/Async';

import styles from './AssessmentModification.module.scss';
import { FirstSection } from './AssessmentModification.types';
import AssessmentModificationQuestions from './AssessmentModificationQuestions';
import SideBar from './SideBar';

const AssessmentModification = () => {
  const history = useHistory();
  const params = useParams<{
    firstSection: FirstSection;
    companyId: string;
    assessmentId: string;
    secondSection: string;
  }>();
  const fetchAssessmentByIdQuery = useFetchAssessmentByIdWithResponses(params.assessmentId);
  const fetchQuestionFamiliesQuery = useFetchQuestionFamilies(
    fetchAssessmentByIdQuery.data?.type?.id ?? ''
  );
  const fetchStatus = mergeFetchStatus(
    toFetchStatus(fetchQuestionFamiliesQuery),
    toFetchStatus(fetchAssessmentByIdQuery)
  );

  const questionFamilies = fetchQuestionFamiliesQuery.data?.sort(
    (a, b) => parseInt(a.displayOrder ?? '') - parseInt(b.displayOrder ?? '')
  );

  const questionFamily = questionFamilies?.find(family => family.id === params.secondSection);

  let lastSectionId = undefined;
  if (questionFamilies && questionFamilies[questionFamilies.length - 1])
    lastSectionId = questionFamilies[questionFamilies.length - 1].id;

  const alertUserOnUnload = (e: { preventDefault: () => void; returnValue: string }) => {
    e.preventDefault();
    e.returnValue = '';
  };

  useEffect(() => {
    window.addEventListener('beforeunload', alertUserOnUnload);
    return () => {
      window.removeEventListener('beforeunload', alertUserOnUnload);
    };
  }, []);

  useEffect(() => {
    if (
      fetchStatus === FETCH_STATUS.FULFILLED &&
      params.secondSection === undefined &&
      questionFamilies &&
      questionFamilies[0]
    ) {
      history.push(`${questionFamilies[0].id}`);
    }
  }, [
    fetchQuestionFamiliesQuery.data,
    history,
    fetchStatus,
    params.secondSection,
    questionFamilies,
  ]);
  return (
    <div className={styles.tabContainer}>
      {(fetchStatus === FETCH_STATUS.PENDING || fetchAssessmentByIdQuery.isFetching) && (
        <div className={styles.loader}>
          <Loader heightInRem={8} />
        </div>
      )}

      {fetchStatus === FETCH_STATUS.REJECTED && (
        <div className={styles.errorContainer}>
          <div className={styles.errorYellowLadder}>
            <IllusEscalierYellow />
          </div>
          <p className={styles.errorTitle}>erreur serveur, connexion perdue</p>
          <p className={styles.errorDescription}>
            dès que vous retrouverez la connexion, vous pourrez recharger la page
          </p>
        </div>
      )}

      {fetchStatus === FETCH_STATUS.FULFILLED &&
        fetchAssessmentByIdQuery.data &&
        !fetchAssessmentByIdQuery.isFetching && (
          <div className={styles.content}>
            <SideBar
              items={
                fetchQuestionFamiliesQuery.data?.map(questionFamily => {
                  return {
                    id: questionFamily.id ?? '',
                    label: questionFamily.label?.toLocaleLowerCase() ?? '',
                  };
                }) ?? []
              }
            />
            <AssessmentModificationQuestions
              assessment={fetchAssessmentByIdQuery.data}
              questionFamily={questionFamily}
              lastSectionId={lastSectionId}
              allQuestions={fetchAssessmentByIdQuery.data.questions}
            />
          </div>
        )}
    </div>
  );
};

export default AssessmentModification;
