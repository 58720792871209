import { Location } from 'history';
import React from 'react';

import { assessmentRoute } from 'src/Containers/MainPage/MainPage.component';
import { ModalName } from 'src/Hooks/Navigation';

export interface ModalRoute {
  route: ModalName;
  component: React.FC<{ modalParameters?: unknown }>;
  backgroundIsOptional?: boolean;
}

export interface CompanyResultRoute {
  route: string;
  component: React.FC<CompanyResultState>;
}

export interface CompanyResultState {
  routeParameters?: {
    companyId?: string;
    companyName?: string;
  };
}

export interface AssessmentPdfState {
  routeParameters?: {
    companyId?: string;
    assessmentId?: string;
  };
}

export interface AssessmentRoute {
  route: string;
  component: React.FC<unknown>;
  routerParameters?: {
    companyId: string;
    assessmentId: string;
  };
}

export interface AssessmentCreationState {
  modalParameters?: {
    companyId?: string;
    companyName?: string;
    contactId?: string;
  };
  background: Location<unknown>;
}

export interface LocationState<T = undefined> {
  modalParameters?: T;
  background: Location<unknown>;
}

export function getEditionRoute({
  companyId,
  assessmentId,
  section,
}: {
  companyId?: string;
  assessmentId?: string;
  section: string;
}) {
  return assessmentRoute.route
    .replace(':companyId?', companyId ?? '')
    .replace(':assessmentId', assessmentId ?? '')
    .replace(':firstSection', section);
}
