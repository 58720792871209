import { ComboBox } from '@randstad-lean-mobile-factory/react-components-core';
import React from 'react';

import { Props } from './ContactPicker.types';

const ContactPicker = ({ onChange, selectedContactId, customId, contacts, fetchStatus }: Props) => {
  return (
    <ComboBox
      id={customId ?? 'contact'}
      useNativeOptionFiltering
      value={contacts.find(item => item.contactId === selectedContactId)}
      searchResults={contacts}
      keyValueExtractor={contact => ({
        key: contact.contactId ?? '',
        value: [contact.name, contact.firstName].filter(item => item !== undefined).join(' '),
      })}
      displayValue="choisissez parmi les contacts"
      onChange={items => onChange(items ?? undefined)}
      fetchStatus={fetchStatus}
    />
  );
};

export default ContactPicker;
