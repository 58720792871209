import { TextArea, WithLightTitle } from '@randstad-lean-mobile-factory/react-components-core';
import React from 'react';

import styles from './COMComponent.module.scss';
import { Props } from './COMComponent.types';

const COMComponent = ({ value, onChange, disabled = false }: Props) => {
  return (
    <>
      <div className={styles.separator} />
      <div className={styles.title}>vos commentaires</div>
      <WithLightTitle title="détails sur le questionnaire ci-dessus">
        <TextArea
          className={styles.comment}
          value={value}
          onChange={e => onChange(e.currentTarget.value)}
          disabled={disabled}
        />
      </WithLightTitle>
    </>
  );
};

export default COMComponent;
