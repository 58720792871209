import {
  Button,
  HorizontalCardWithTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import moment from 'moment';
import React from 'react';
import ContentLoader from 'react-content-loader';
import { useHistory } from 'react-router-dom';

import AssessmentActions from 'src/Components/AssessmentActions';
import { ErrorMessage } from 'src/Components/ErrorMessage';
import { useFetchAssessments } from 'src/Hooks/Assessment/useFetchAssessments';
import { AssessmentStatus } from 'src/Services/API';
import { getEditionRoute } from 'src/Services/Routing';
import { toDate } from 'src/Utils/dateTransformers';

import styles from './CompanyAssessmentsList.module.scss';
import { Props } from './CompanyAssessmentsList.types';

const CompanyAssessmentsList = ({ companyId }: Props) => {
  const history = useHistory();
  const {
    isSuccess,
    isLoading,
    isError,
    isFetching,
    data,
    hasNextPage,
    fetchNextPage,
  } = useFetchAssessments({ companyId });
  const assessments = data;
  const assessmentsNumber = data?.pages.flat(1).length ?? 0;
  return (
    <div>
      {isLoading && (
        <>
          <div className={styles.numberOfAssessments}>
            <ContentLoader
              height="2rem"
              width="12rem"
              uniqueKey="edpNumber"
              backgroundColor="#d7d7d7"
            >
              <rect x="0%" y="10" rx="4" ry="4" width="100%" height="15" />
            </ContentLoader>
          </div>
        </>
      )}

      {isError && (
        <ErrorMessage message="Une erreur est survenue dans la récupération des bilans de satisfaction" />
      )}

      {isSuccess && (
        <>
          <div className={styles.numberOfAssessments}>
            {`${assessmentsNumber} bilan${
              assessmentsNumber > 1 ? 's' : ''
            } satisfaction enregistré${assessmentsNumber > 1 ? 's' : ''}`}
          </div>
          {assessments?.pages.map(assessmentGroup =>
            assessmentGroup.map(assessment => (
              <HorizontalCardWithTitle
                key={assessment.id}
                title={`${assessment.contacts?.[0]?.name ?? "pas d'interlocuteur associé"} ${
                  assessment.contacts?.[0]?.firstName ?? ''
                }`}
                subtitles={[
                  `${
                    assessment.services?.[0]
                      ? assessment.services[0].label
                      : 'pas de service associé'
                  } | ${assessment.type?.label}`,
                  `${assessment.status === AssessmentStatus.Finished ? 'terminé | ' : ''} ${
                    assessment.assessmentDate
                      ? `date de création : ${moment(toDate(assessment.assessmentDate)).format(
                          'L'
                        )}`
                      : 'date de création inconnue'
                  }`,
                ]}
                rightActions={
                  <AssessmentActions
                    companyId={assessment.company?.id}
                    assessmentId={assessment.id}
                  />
                }
                onClick={() => {
                  history.push(
                    getEditionRoute({
                      companyId,
                      assessmentId: assessment.id,
                      section: 'content',
                    }),
                    { assessmentType: assessment.type?.label }
                  );
                }}
              />
            ))
          )}
          <div className={styles.displayMore}>
            {isFetching && (
              <ContentLoader
                height="5rem"
                width="8rem"
                uniqueKey="displayMore"
                backgroundColor="#d7d7d7"
              >
                <rect x="0%" y="0" rx="4" ry="4" width="100%" height="50" />
              </ContentLoader>
            )}
            {!isFetching && hasNextPage && (
              <Button.Tertiary
                onClick={() => {
                  fetchNextPage();
                }}
              >
                afficher plus
              </Button.Tertiary>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default CompanyAssessmentsList;
