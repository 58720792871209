import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { useSelector } from 'react-redux';

import { getCurrentBrand } from 'src/Redux/Perimeter/Selectors';
import { Question, RepositoriesService } from 'src/Services/API';

import { QueryKeys } from '../types';

export const useFetchQuestionFamilies = (
  assessmentTypeId: string,
  extraOptions?: UseQueryOptions<Question[], unknown, Question[], (string | undefined)[]>
) => {
  const currentBrand = useSelector(getCurrentBrand);
  return useQuery(
    [QueryKeys.fetchQuestionFamilies, assessmentTypeId, currentBrand?.brandCodeApiHeader],
    async () => {
      if (!currentBrand || !currentBrand.brandCodeApiHeader) {
        throw new Error('No current brand or has an invalid current brand');
      }
      return RepositoriesService.repositoriesControllerGetQuestionFamilies({
        assessmentTypeId,
        brandCode: currentBrand.brandCodeApiHeader,
      });
    },
    {
      ...extraOptions,
      enabled: assessmentTypeId !== '' && assessmentTypeId !== undefined,
      refetchOnWindowFocus: false,
    }
  );
};
