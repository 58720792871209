import React from 'react';

import COMComponent from '../QuestionComponents/COMComponent';
import R2Component from '../QuestionComponents/R2Component';
import R5Component from '../QuestionComponents/R5Component';
import R6Component from '../QuestionComponents/R6Component';

import { Props } from './QuestionContainer.types';

const QuestionContainer = ({ label, type, response, onChange, disabled = false }: Props) => {
  switch (type) {
    case 'R2':
      return (
        <R2Component
          label={label}
          value={response?.value ?? ''}
          onChange={onChange}
          disabled={disabled ?? false}
        />
      );
    case 'R5':
      return (
        <R5Component
          label={label}
          mandatory={false}
          value={response?.value ?? ''}
          onChange={onChange}
          disabled={disabled ?? false}
          canBeReset
        />
      );
    case 'R5G':
      return (
        <R5Component
          label={label}
          mandatory={true}
          value={response?.value ?? ''}
          onChange={onChange}
          disabled={disabled ?? false}
        />
      );
    case 'R6':
      return (
        <R6Component
          label={label}
          value={response?.value ?? ''}
          onChange={onChange}
          disabled={disabled ?? false}
          canBeReset
        />
      );
    case 'COM':
      return (
        <COMComponent
          value={response?.value ?? ''}
          onChange={onChange}
          disabled={disabled ?? false}
        />
      );
    default:
      console.error(`Le type "${type}" n'est pas défini`);
      return <div>[Question non définie ({type})]</div>;
  }
};

export default QuestionContainer;
