import { ExclusiveSelectionGroup } from '@randstad-lean-mobile-factory/react-components-core';
import React from 'react';

import styles from './R2Component.module.scss';
import { Props } from './R2Component.types';

const R2Component = ({ label, value, onChange, disabled = false }: Props) => {
  const items = Array(2)
    .fill(undefined)
    // 1: yes, 0: no
    .map((_, index) => (index === 0 ? '1' : '0'));
  return (
    <div className={styles.container}>
      <div className={styles.label}>{label}</div>
      <div className={styles.question}>
        <ExclusiveSelectionGroup
          selected={value}
          values={items}
          getKey={key => key}
          getStringValue={value => (parseInt(value, 10) === 1 ? 'oui' : 'non')}
          setSelected={value => onChange(value ?? '')}
          disabled={disabled}
          canBeReset
        />
      </div>
    </div>
  );
};

export default R2Component;
