import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { PerimeterState } from './Types';

export const initialState: PerimeterState = {};

const { reducer, actions } = createSlice({
  name: 'perimeter',
  initialState,
  reducers: {
    setPerimeter: (state, action: PayloadAction<PerimeterState['perimeter']>) => {
      state.perimeter = action.payload;
    },
    setCurrentBrand: (state, action: PayloadAction<PerimeterState['currentBrand']>) => {
      state.currentBrand = action.payload;
    },
    setCurrentAgency: (state, action: PayloadAction<PerimeterState['currentAgency']>) => {
      state.currentAgency = action.payload;
    },
    resetCurrent: state => {
      state.currentBrand = undefined;
      state.currentAgency = undefined;
    },
  },
});

export const perimeterReducer = reducer;
export const perimeterActions = actions;
