import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';

import { getCurrentBrand } from 'src/Redux/Perimeter/Selectors';
import { AssessmentDetails, AssessmentsService } from 'src/Services/API';

import { QueryKeys } from '../types';

export const useFetchAssessmentById = (
  assessmentId: string,
  extraOptions?: UseQueryOptions<
    AssessmentDetails,
    unknown,
    AssessmentDetails,
    (string | undefined)[]
  >
) => {
  const currentBrand = useSelector(getCurrentBrand);
  return useQuery(
    [QueryKeys.fetchAssessmentById, assessmentId, currentBrand?.brandCodeApiHeader],
    async () => {
      if (!currentBrand || !currentBrand.brandCodeApiHeader || !currentBrand.agencies) {
        throw new Error('No current brand or has an invalid current brand');
      }
      const assessment = await AssessmentsService.assessmentsControllerGetAssessmentDetail({
        brandCode: currentBrand.brandCodeApiHeader,
        id: assessmentId,
      });
      return assessment;
    },
    {
      refetchOnWindowFocus: false,
      ...extraOptions,
    }
  );
};
