import { Button, ParametersPopup } from '@randstad-lean-mobile-factory/react-components-core';
import { CircleRandstad, Gear } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import classnames from 'classnames';
import React, { useState } from 'react';

import useAuth from 'src/Hooks/Authentication/useAuth';
import { useAuthenticatedUser } from 'src/Hooks/Authentication/useAuthenticatedUser';
import { useModalWithLocation } from 'src/Hooks/Navigation';

import styles from './Parameters.module.scss';

const Parameters = () => {
  const [isOpened, setIsOpened] = useState(false);
  const { logout } = useAuth();
  const { givenName, familyName, email } = useAuthenticatedUser();
  const { open } = useModalWithLocation('perimeter-selection');

  return (
    <ParametersPopup
      trigger={
        <div>
          <Button.Tertiary
            className={classnames(styles.gearButton, { [styles.openGearButton]: isOpened })}
          >
            <Gear className={isOpened ? styles.gearIcon : undefined} />
          </Button.Tertiary>
        </div>
      }
      position="bottom right"
      onOpen={() => setIsOpened(true)}
      onClose={() => setIsOpened(false)}
      firstName={givenName}
      lastName={familyName}
      email={email}
      onLogout={() => logout({ returnTo: `${process.env.REACT_APP_AUTH0_REDIRECT_URI}login` })}
      version={process.env.REACT_APP_VERSION && `${process.env.REACT_APP_VERSION}`}
    >
      <Button.Tertiary
        className={styles.button}
        onClick={() => {
          open({});
        }}
      >
        <CircleRandstad className={styles.circleRandstad} />
        choix de vos unités
      </Button.Tertiary>
    </ParametersPopup>
  );
};

export default Parameters;
