import { PopupMenu, Button } from '@randstad-lean-mobile-factory/react-components-core';
import { ThreeDots, Pencil } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import classnames from 'classnames';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { getEditionRoute } from 'src/Services/Routing';

import PDFGenerationButton from '../PDFGenerationButton';

import styles from './AssessmentActions.module.scss';
import { Props } from './AssessmentActions.types';

const AssessmentActions = ({ assessmentId, companyId }: Props) => {
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  return (
    <PopupMenu
      position="left top"
      width={248}
      onOpen={() => setIsOpen(true)}
      onClose={() => {
        setIsOpen(false);
      }}
      trigger={
        <Button.Tertiary className={classnames(styles.trigger, { [styles.activeTrigger]: isOpen })}>
          <ThreeDots className={styles.icon} />
        </Button.Tertiary>
      }
    >
      <PopupMenu.Item
        icon={<Pencil />}
        text="consulter / modifier"
        onClick={() => {
          history.push(getEditionRoute({ companyId, assessmentId, section: 'content' }));
        }}
      />
      <PDFGenerationButton assessmentId={assessmentId} companyId={companyId} />
    </PopupMenu>
  );
};
export default AssessmentActions;
