import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { useSelector } from 'react-redux';

import { getCurrentBrand } from 'src/Redux/Perimeter/Selectors';
import { AssessmentDetails, AssessmentsService, UpdateAssessmentBody } from 'src/Services/API';

import { MutationKeys, QueryKeys } from '../types';

export const useUpdateAssessment = (
  extraOptions?: UseMutationOptions<
    AssessmentDetails,
    string,
    { id: string; assessment: UpdateAssessmentBody }
  >
) => {
  const currentBrand = useSelector(getCurrentBrand);
  const queryClient = useQueryClient();
  const { onSuccess, ...rest } = extraOptions ?? {};
  return useMutation(
    [MutationKeys.updateAssessment],
    async ({ id, assessment }: { id: string; assessment: UpdateAssessmentBody }) => {
      if (!currentBrand?.brandCodeApiHeader) {
        throw new Error('No current brand or has an invalid current brand');
      }
      return await AssessmentsService.assessmentsControllerUpdateAssessment({
        id,
        body: {
          ...assessment,
          brandCode: currentBrand.brandCodeApiHeader,
        },
      });
    },
    {
      onSuccess: (...args) => {
        queryClient.invalidateQueries({ queryKey: [QueryKeys.fetchAssessments] });
        queryClient.invalidateQueries({ queryKey: [QueryKeys.fetchAssessmentById] });
        onSuccess?.(...args);
      },
      ...rest,
    }
  );
};
