import React, { useEffect, useState } from 'react';
import { Route, Router, Switch, useHistory, useParams, useLocation } from 'react-router-dom';

import DetailButton from 'src/Components/DetailButton';
import {
  FormStatusContext,
  useFormNavigationContextElements,
} from 'src/Hooks/Navigation/useFormNavigationContextElements';
import { FETCH_STATUS } from 'src/Redux/Types';
import { getEditionRoute } from 'src/Services/Routing';
import history from 'src/Utils/history';

import { assessmentModificationRoute, KPIRoute } from '../MainPage/MainPage.component';
import NavigationWarningModal from '../Modals/NavigationWarningModal';

import styles from './AssessmentDetails.module.scss';
import { FirstSection, getNavigation } from './AssessmentModification/AssessmentModification.types';
import { BreadCrumb } from './AssessmentModification/BreadCrumb';
import SegmentedMenu from './AssessmentModification/SegmentedMenu';

const AssessmentDetails = () => {
  const localHistory = useHistory();
  const location = useLocation<{ assessmentType: string }>();

  const params = useParams<{
    firstSection: FirstSection;
    companyId: string;
    assessmentId: string;
  }>();

  const navContextElement = useFormNavigationContextElements();

  const [type, setType] = useState('bilan');

  useEffect(() => {
    if (location.state) setType(location.state?.['assessmentType']);
  }, [location.state]);
  return (
    <FormStatusContext.Provider
      value={{
        hasFormBeenTouched: navContextElement.hasFormBeenTouched,
        setHasFormBeenTouched: navContextElement.setHasFormBeenTouched,
        destination: navContextElement.destination,
        openNavWarningModal: navContextElement.openNavWarningModal,
        closeNavWarningModal: navContextElement.closeNavWarningModal,
        navWarningModalState: navContextElement.navWarningModalState,
        openPdfWarningModal: navContextElement.openPdfWarningModal,
        closePdfWarningModal: navContextElement.closePdfWarningModal,
        handleSubmit: navContextElement.handleSubmit,
        updateValidationStatus: navContextElement.updateValidationStatus,
        fetchStatus: navContextElement.fetchStatus,
        pdfFetchStatus: navContextElement.pdfFetchStatus,
        setFetchStatus: navContextElement.setFetchStatus,
        setPdfFetchStatus: navContextElement.setPdfFetchStatus,
        pdfWarningModalState: navContextElement.pdfWarningModalState,
        formState: navContextElement.formState,
        setFormState: navContextElement.setFormState,
      }}
    >
      <div className={styles.container}>
        <div className={styles.topBar}>
          <BreadCrumb />
          <DetailButton assessmentId={params.assessmentId} companyId={params.companyId} />
        </div>
        <div className={styles.tabsContainer}>
          <SegmentedMenu
            controls={Object.entries(getNavigation(type))}
            selected={params.firstSection ?? FirstSection.CONTENT}
            keyValueExtractor={([section, { name }]) => ({ key: section, value: name })}
            onSelectionChange={([section]) => {
              if (navContextElement.hasFormBeenTouched) {
                navContextElement.openNavWarningModal({
                  destination: getEditionRoute({
                    companyId: params.companyId,
                    assessmentId: params.assessmentId,
                    section,
                  }),
                });
                navContextElement.setFetchStatus(FETCH_STATUS.FULFILLED);
              } else {
                localHistory.push(
                  getEditionRoute({
                    companyId: params.companyId,
                    assessmentId: params.assessmentId,
                    section,
                  })
                );
              }
            }}
          />
        </div>
        <div className={styles.content}>
          <Router history={history}>
            <Switch>
              <Route
                path={assessmentModificationRoute.route}
                component={assessmentModificationRoute.component}
              />
              <Route path={KPIRoute.route} exact component={() => <div>kpi</div>} />
            </Switch>
          </Router>
        </div>
      </div>
      <NavigationWarningModal
        open={navContextElement.navWarningModalState}
        onClose={() => navContextElement.closeNavWarningModal()}
      />
    </FormStatusContext.Provider>
  );
};

export default AssessmentDetails;
