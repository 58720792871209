import { OSMCommonModelDateDto } from 'src/Services/API';

export function toDate(date: OSMCommonModelDateDto): Date {
  return new Date(
    Date.UTC(
      date.year || new Date().getUTCFullYear(),
      (date.month || new Date().getUTCMonth() + 1) - 1,
      date.day
    )
  );
}
