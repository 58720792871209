import { AutoSuggest } from '@randstad-lean-mobile-factory/react-components-core';
import throttle from 'lodash.throttle';
import React, { useState, useMemo } from 'react';

import { useFetchCompanies } from 'src/Hooks/Companies';
import { FETCH_STATUS } from 'src/Redux/Types';
import { toFetchStatus } from 'src/Services/Async';

import { Props } from './CompanyPicker.types';

const CompanyPicker = ({ onCompanyChange, className }: Props) => {
  const [keyword, setKeyword] = useState('');
  const { data, isSuccess, isError, isLoading } = useFetchCompanies(keyword);
  const throttledSetKeyword = useMemo(() => throttle(setKeyword, 500), [setKeyword]);

  return (
    <div className={className}>
      <AutoSuggest
        id="companies"
        searchResults={(data ?? []).slice(0, 100)}
        onSearch={keyword => throttledSetKeyword(keyword)}
        withSubLabel
        keyValueExtractor={item => ({
          key: item.companyId ?? '',
          value: `${item.companyName} (${item.siret})`,
          subValue: `${item.companyAddress?.postalCode} - ${item.companyAddress?.city}`,
        })}
        onChange={items => {
          onCompanyChange(items[0]);
        }}
        minLengthToSearch={3}
        fetchStatus={
          keyword.length > 2
            ? toFetchStatus({ isSuccess, isError, isLoading })
            : FETCH_STATUS.FULFILLED
        }
      />
    </div>
  );
};

export default CompanyPicker;
